import React, { useEffect, useContext, useState } from "react";
import logo from "../../../assets/images/user/logo.png";
import gameOverImg from "../../../assets/images/game-over-img.png";
import timesUpImg from "../../../assets/images/timeup.jpg";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../../Admin/Config/config";
import { SocketContext } from "../../../contexts/socketConnection";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { date } from "yup";
import { leaderBoardStatus } from '../Services/Service';

import useInterval from "../../../hooks/useInterval"




export default function GameFinishedPage({ timeoutHeader, timeoutText, leaderStats, timeOver, endTime, handleTicking, totalPodTime, leaderStatus }) {
  const navigate = useNavigate();
  const { token, gameId, podId } = useParams();
  const socket = useContext(SocketContext);
  const [completed, setCompleted] = useState("")
  const [show, setShow] = useState(localStorage.getItem("completed") ? localStorage.getItem("completed") : false)
  const [leader, setLeader] = useState(false)
  const [leaderRes, setLeaderRes] = useState({})
  const [endTimeRes, setEndTime] = useState(0)
  const [time, setTime] = useState(0)
  const [refresh, setRefresh] = useState()
  const [msg, setMsg] = useState("")

  // function formatTime(endTime) {
  //   if (typeof endTime !== 'number') {
  //     throw new Error('The endTime argument must be a number representing milliseconds.');
  //   }

  //   let ms =  endTime - Date.now()
  //   let x = ms / 1000
  //   let seconds = Math.floor(x % 60)
  //   seconds = seconds.toString().length < 2 ? '0' + seconds : seconds
  //   x /= 60
  //   let minutes = Math.floor(x % 60)
  //   minutes = minutes.toString().length < 2 ? '0' + minutes : minutes
  //   x /= 60
  //   const hours = Math.floor(x % 24)
  //   const formattedTime = hours ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
  //   return formattedTime;
  // }




  function formatTime(endTime) {
    if (typeof endTime !== 'number') {
      throw new Error('The endTime argument must be a number representing milliseconds.');
    }

    const currentTime = Date.now(); // Get the current time in milliseconds
    const timeDifferenceInMilliseconds = endTime - currentTime;
    const timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60); // Convert milliseconds to minutes
    return Math.ceil(timeDifferenceInMinutes);
  }

  useInterval(() => {
    if (time <= 1 && leader === false) {
      getData()
      // setLeader(0)
      // setLeaderRes(0)
      setTime(0)
    } else {
      setTime(formatTime(endTimeRes))
    }
  }, 10000);
  //(60 - new Date().getSeconds()) * 1000

  const labelData = useSelector((state) =>
    state.game.data &&
      state.game.data.labelData &&
      Object.keys(state.game.data.labelData).length
      ? state.game.data.labelData
      : {}
  );




  useEffect(() => {
    socket.emit('update', {
      podId
    })

    let rs = (data) => {
      if (data?.leaderboardstatus) {
        setLeader(data?.leaderboardstatus)
      }
    }
    socket.on("refreshStatus", rs)
    return () => {
      socket.off("refreshStatus", rs)
    }
  }, [])

  useEffect(() => {
    getData()
    document.body.classList.remove("loading-indicator");
  }, [])

  const getData = async () => {
    let body = {
      podId: podId
    }
    const res = await leaderBoardStatus(body);
    console.log("first", res.data.data);
    setMsg(res?.data?.data?.message)
    setLeader(res.data.data.leaderboardstatus)
    setLeaderRes(res.data.data)


    socket.emit("refreshStatus", {
      podId: podId,
      leaderboardstatus: res.data.data.leaderboardstatus
    })

    if (res?.data?.data.leaderBoardStatus) {
      socket.emit("refreshStatus", {
        podId: podId,
        leaderboardstatus: res.data.data.leaderboardstatus
      })
    }

    if (res?.data?.data?.podEndTime > Date.now()) {
      setEndTime(res.data.data.podEndTime)
      setTime(formatTime(res?.data?.data?.podEndTime))
    }

    console.log("second", res.data.data)
    document.body.classList.remove("loading-indicator");
  }

  const handleLeaderBoard = () => {
    navigate(`/user/${token}/${gameId}/${podId}/leaderboard`, { state: { totalPodTime: totalPodTime, replace: true } });
  };


  const compl = (e) => {
    console.log(e)
    setShow(e)
    localStorage.setItem("completed", e)
  }



  return (
    <div className="game-over-screen-bg" >
      <div className="container pt-5">
        <div className="form-bg-inner">
          <div className="logo text-center">
            <img width="320px" src={logo} />
          </div>
          <div className="game-over-screen">
            <figure className="game-over-screen-img">
              <img src={timeOver ? (labelData?.gameTimeoutImage ? url + labelData?.gameTimeoutImage : url + "public/admin/default/timeup.jpg") : (labelData?.gameCompletedImage ? url + labelData?.gameCompletedImage : url + "public/admin/default/gameCompleted.png")} />
            </figure>
            <figcaption className="game-over-screen-content">
              <h2 className="pb-2">
                {timeOver ? labelData?.gameTimeoutHeader : labelData?.gameCompleteLabel}
              </h2>
              {timeOver ? (
                labelData?.gameTimeoutText
              ) : (
                <>
                  <p dangerouslySetInnerHTML={{ __html: labelData?.gameCompleteDesc }}></p>
                  {/* <br />and wait till other teams complete the game */}
                </>
              )}
            </figcaption>
            {/* <div className="game-form col-md-4 m-auto text-center" style={{ visibility: "hidden" }}>
              {(endTime !== null && endTime !== undefined) && (
                <>
                  <Countdown
                    date={endTime}
                    style={{ visibility: "hidden" }}
                    intervalDelay={1000}
                    autostart
                    precision={3}
                    renderer={renderer}
                    onTick={() => {
                      handleTicking(endTime);
                    }}
                    onComplete={(e) => { compl(e.completed) }}
                  />
                </>
              )}

            </div> */}



            {/* {leader === false && time >= 1 ? <p>Leaderboard will appear anytime once all teams finish within an {time}-minute timeout</p> : null} */}
            {/* {(leaderStatus == false || leader == false) && leaderRes?.message} */}
            {/* {leader === false && time >= 1 ? <p>Leaderboard will appear in  {time == 1 ? `${time} minute` : `${time} minutes`}  </p> : null} */}
            {/* <br />{msg ? <sub>({msg})</sub> : null} */}
            {leaderStatus === true || leader === true ? <button className="styledBtn px-btn mb-4" onClick={handleLeaderBoard}>
              <span>
                {labelData?.ViewLeaderboardButton}
              </span>
            </button> : <button className="styledBtn styledBtn-disabled disabled mb-4 px-btn" onClick={getData}>
              <span>
                {/* {labelData?.RefreshButton} */}
                {labelData?.ViewLeaderboardButton}
              </span>
            </button>}
            {/* <button className="styledBtn mb-4" onClick={handleLeaderBoard}>
              VIEW LEADERBOARD
              || show || timeOver
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
