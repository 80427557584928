import React, { useState, useContext } from 'react';
import { capitalize } from 'lodash';

import { styled } from '@material-ui/styles';
import { Box, Card, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Button, Modal, ModalBody, ModalDialog } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import './Instruction.css';
import logo from '../../assets/images/logo.svg';
import video from '../../assets/images/video-img.png';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CallMadeIcon from '@mui/icons-material/CallMade';

import { getContent, getPodName } from './Services/Service';
import { useEffect } from 'react';
import { Base_URL } from './Config/Config';
import { useSelector } from 'react-redux';
import { played } from '../../Actions/ActionCreators';
import { useDispatch } from 'react-redux';
import { SocketContext } from '../../contexts/socketConnection';
import closeIcon from "../../assets/images/close-icon.png";
import vedioPlay from "../../assets/images/vedioplay.svg";
import arrowIcon from "../../assets/images/arrow.svg"
import { url } from '../Admin/Config/config';

const RootStyle = styled('div')(() => ({
  minHeight: '100vh',
  transform: "scale(0.8)"

}));


const ButtonStyle = styled('button')(() => ({
  backgroundColor: "#1D1C1C",
  width: 'auto',
  color: "#FFF",
  textAlign: "center",
  border: "1px solid #FFF",
  fontSize: "20px",
  textTransform: 'uppercase',
  fontFamily: 'PFDinMonoMedium',
  // paddingRight: "30px",
  margin: '10px auto auto auto',
  display: 'flex',
  alignItems: 'center',
  background: 'transparent',
  padding: '3px 10px'
}));


const Instruction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { token, gameId, podId } = useParams();
  const socket = useContext(SocketContext);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [data, setData] = useState({
    description: "",
    url: ""
  })
  const [labelData, setLabelData] = useState();
  const [members, setMembers] = useState([]);
  const teamDetail = useSelector((state) => (state.team.data.teamDetails));
  const currentUser = useSelector((state) => (state.team.data.currentUser));
  const BasePath = pathname.split("/")

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const videoPlayed = useSelector(state => state.play.played);

  const handleMoveOn = () => {
    // const BasePath = pathname.split("/")
    // BasePath.pop()
    // // console.log(BasePath.join("/"))
    // navigate(`${BasePath.join("/")}/game`);
    document.body.classList.add('loading-indicator');
    socket.emit("startGame", {
      podId: podId,
      roomName: teamDetail.teamName,
      teamId: teamDetail._id
    })
    document.body.classList.remove("loading-indicator");

  }

  const getData = async () => {
    setLoading(true);
    document.body.classList.add('loading-indicator');
    const res = await getPodName({ _id: podId, gameId: gameId });

    const data = res?.data?.labelData
    console.log("first", res?.data?.labelData);
    setLabelData(data);
    document.body.classList.remove('loading-indicator');

    setData({
      ...data,
      description: data.description,
      url: data?.contentVideo ? url + data?.contentVideo : url + "public/admin/default/1658826330902-Need For Speed -[GMV].mp4"
    })
    setLoading(false);
  }

  const handlePlayVideo = () => {
    setShow(true);
    dispatch(played())
    // socket.emit("watchVideo", {
    //   roomName: teamDetail.teamName
    // })
  }

  useEffect(() => {
    getData()
    /* Connect to socket and join the room */
    socket.on('connect', () => {
      socket.emit('joinRoom', {
        teamMemberName: currentUser,
        roomName: teamDetail?.teamName,
        teamId: teamDetail._id,
        podId: podId
      })
    })

    // socket.on("watchVideo", ()=>{
    //   setShow(true);
    //   dispatch(played())
    // });

    socket.on("startGame", () => {
      BasePath.pop()
      console.log(BasePath.join("/"))
      navigate(`/user/${token}/${gameId}/${podId}/game`, { replace: true });
    });


    socket.on('getTeamName', (data) => {
      if (data.teamMembers) {
        setMembers([...data.teamMembers]);
      }
    })

    const interval = setInterval(() => {
      // getTeam()
      socket.emit('getTeamName', {
        gameId: gameId,
        podId: podId,
        token: token,
      })
    }, 1000);

    return () => {
      clearInterval(interval);
      setMembers([]);
    }


  }, [])

  // console.log("labelData", labelData)

  return (
    <>
      <div className="form-bg">
        <div className="form-bg-inner">
          <div className="text-center">
            <img className='logo-screen' width="320px" src={logo} />
          </div>
          <div className='play-video-box text-center'>
            <div className="play-head">
              <h3>{labelData?.playScreenLabel}</h3>

              <p>{labelData?.playScreenDesc}</p>
            </div>
            <div className="play-body" style={{
              filter: loading ? "blur(20px)" : "none",
              minHeight: "240px"
            }}>
              <figure>
                {!loading ? labelData?.contentVideothumbnail ? <img src={url + labelData?.contentVideothumbnail} /> : <img src={url + "public/admin/default/videoThumbnail.jpg"} /> : null}

              </figure>
              <span className="play-icon" style={{ cursor: 'pointer' }} onClick={() => { handlePlayVideo() }}><img src={vedioPlay} /></span>
            </div>
            <div className="play-footer">
              {/* <p ></p> */}

              {videoPlayed ?
                <>
                  <span className="yallow-content yallow-color">{labelData?.textUnderVideo}</span>
                  <button className='styledBtn px-btn' onClick={() => { handleMoveOn() }}>
                    <span>
                      {labelData?.startGameButton}
                    </span>
                    {/* <span>
                  <svg id="arrow.e0e8f1ef" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path id="Path_96" data-name="Path 96" d="M0,0H24V24H0Z" fill="none"/>
                  <path id="Path_97" data-name="Path 97" d="M16,9.414,7.4,18.021,5.983,16.607,14.589,8H7V6H18V17H16Z"/>
                  </svg>
                </span> */}
                  </button>
                </>
                : <button className='styledBtn px-btn' onClick={() => { handlePlayVideo() }}>
                  <span>
                    {labelData?.watchVideoButtonLabel}
                  </span>
                </button>}
            </div>
          </div>
        </div>
        <div className="teammatesJoining">
          <h1>
            {members.length > 0 ? `${labelData?.teammatesJoining} ` : `${labelData?.teammatesJoining}`} <span>{members.length > 0 && `${members.map((member, idx) => member.name)}`.split(",").join(", ")}</span>
          </h1>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <Modal size="xl" className="video-model-box" show={show} onHide={() => { handleClose() }} centered backdrop="static">
        <Box style={{ display: 'flex', padding: "1%", backgroundColor: "#1D1C1C", justifyContent: "flex-end" }}>
          <Typography style={{ color: "#FFFB00", gap: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={handleClose}>
            <img src={closeIcon} style={{ cursor: 'pointer', height: '20px' }} />
            <span style={{ color: "#FFF", letterSpacing: '0.32px', fontSize: '14px' }}>{labelData?.closeButtonOnVIdeo}</span>
          </Typography>
        </Box>
        <ReactPlayer
          height="auto"
          width="100%"
          controls
          onContextMenu={e => e.preventDefault()}
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          playing={true}
          url={data.url}
          onEnded={handleClose}
          style={{ background: "#1D1C1C" }}
        />
      </Modal>
    </>
  );
};

export default Instruction;
