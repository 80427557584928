import React, { useState, useCallback } from 'react';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { ChapterAdd } from '../../Services/ChapterServices';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  Card,
  InputLabel,
  Button as MButton,
  DialogActions,
  Typography,
  CardActions,
  FormHelperText,
  Tooltip,
  Chip,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper
} from '@material-ui/core';
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import EditPuzzleDisplay from '../EditChapter/EditPuzzleDisplay';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Stack } from '@mui/material';
import AudioPlayerAdmin from '../../../../components/AudioPlayerAdmin';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { reorder } from '../../../../utils/dragMethods';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const AddChapter = ({ gameId, setShowChapter, refreshList }) => {

  const navigate = useNavigate();
  const allowed_ext = ['png', 'jpg', 'jpeg', 'mp3', 'mp4', 'wav', 'aac', 'ogg', 'm4a', 'mpeg', 'mov'];
  const allowedFileType = ['image', 'audio', 'video'];
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++
  Dynamic hints and clues handling starts
  ++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  const [upload, setUpload] = useState({
    title: "",
    file: "",
    description: "",
    text: "",
    preview: "",
    points: "",
    type: "text",
    fileType: "",
    show: false,
    upload: '',
    fileIndex: '',
    index: 0
  })

  const [del, setDel] = useState({
    show: false,
    type: null,
    idx: null,
    fileIdx: null
  });


  const [uploadErr, setUploadErr] = useState({
    title: "",
    file: "",
    description: "",
    points: "",
    text: "",
    type: "",
  });

  const [uploadFlag, setUploadFlag] = useState({
    title: false,
    file: false,
    description: false,
    points: false,
    text: false,
    type: false,
  });


  const handleOpenDel = (type, idx, fileIdx) => {
    setDel({
      ...del,
      type: type,
      show: true,
      idx: idx,
      fileIdx: fileIdx
    });
  }

  const handleConfirmDel = () => {
    if (del.type === 'hint') {
      handleRemoveHintUpload(del.idx, del.fileIdx);
    }
    if (del.type === 'clue') {
      handleRemoveClueUpload(del.idx, del.fileIdx);
    }
    setDel({
      ...del,
      show: false,
      type: null,
      idx: null,
      fileIdx: null
    });

  }

  const handleCancelDel = () => {

    setDel({
      ...del,
      show: false,
      type: null,
      idx: null,
      fileIdx: null
    });

  }


  const handleOpenUpload = (upload) => {

    setUpload({
      ...upload,
      show: true,
      upload: upload
    })
  }

  const handleCloseUpload = () => {
    setUpload({
      title: "",
      file: "",
      description: "",
      text: "",
      preview: "",
      points: "",
      type: "text",
      fileType: "",
      show: false,
      upload: ''
    })
  }

  const handleChangeUpload = (e) => {
    setUpload({
      ...upload,
      [e.target.name]: e.target.value
    });
    setUploadErr({
      ...uploadErr,
      [e.target.name]: '',
      [e.target.name + 'Err']: false
    })
  }

  const handleDropFiles = (e) => {
    let inputFile = e.target.files[0];
    let inputType = e.target.files[0].type;
    let inputURL = URL.createObjectURL(inputFile);
    if (allowed_ext.includes(inputType.split("/")[1]) || allowedFileType.includes(inputType.split("/")[0])) {
      setUpload({
        ...upload,
        file: inputFile,
        preview: inputURL,
        fileType: inputType
      })

    } else {
      setUpload({
        ...upload,
        file: "",
        preview: "",
        fileType: ""
      })

      toast.error(`Only ${allowed_ext} are allowed`)
    }

  };

  const handleRemoveHintUpload = (idx, fileIdx) => {
    let Elements = [...values.hints]
    let Files = [...values.hintFiles];
    Elements.splice(idx, 1);
    Files.splice(fileIdx, 1);
    setValues({
      ...values,
      hints: Elements,
      hintFiles: Files,
    })
  }

  const handleRemoveClueUpload = (idx, fileIdx) => {
    let Elements = [...values.clues]
    let Files = [...values.clueFiles];
    Elements.splice(idx, 1);
    Files.splice(fileIdx, 1);
    setValues({
      ...values,
      clues: Elements,
      clueFiles: Files,
    })
  }

  const validateUploadField = async () => {
    let flag = true;
    let upError = { ...uploadErr };
    let upFlag = { ...uploadFlag };
    let upData = { ...upload };
    Object.keys(upError).map((key, idx) => {
      if (key === 'title' || (key === 'text' && upData['type'] === 'text')) {
        if (!Yup.string().required().isValidSync(upData[key])) {
          flag = false;
          upError[key] = "This Field is required";
          upFlag[key] = true;
        } else
          if (!Yup.string().strict().trim().isValidSync(upData[key])) {
            flag = false;
            upError[key] = "Trailing white spaces are not allowed";
            upFlag[key] = true;
          }
          // else
          //   if (!Yup.string().min(3).isValidSync(upData[key])) {
          //     flag = false;
          //     upError[key] = "This field must contain at least 3 characters";
          //     upFlag[key] = true;
          //   } 
          else
            if (key === 'title') {
              if (!Yup.string().max(60).isValidSync(upData[key])) {
                flag = false;
                upError[key] = "This field can only contain maximum 60 characters";
                upFlag[key] = true;
              }
            } else {
              if (!Yup.string().max(500).isValidSync(upData[key])) {
                flag = false;
                upError[key] = "This field can only contain maximum 500 characters";
                upFlag[key] = true;
              }
            }
      } else {

        upError[key] = "";
        upFlag[key] = false;
      }
      if (key === 'points' && upData['upload'] === 'hint') {
        if (!Yup.string().required().isValidSync(upData[key])) {
          flag = false;
          upError[key] = "This Field is required";
          upFlag[key] = true;
        } else
          if (!Yup.string().matches(/^[0-9]{1,3}$/).isValidSync(upData[key])) {
            flag = false;
            upError[key] = "Please enter a valid point number";
            upFlag[key] = true;
          } else {
            upError[key] = "";
            upFlag[key] = false;
          }
      }
      if (key === 'type') {
        if (!Yup.string().required().isValidSync(upData[key])) {
          flag = false;
          upError[key] = "This Field is required";
          upFlag[key] = true;
        } else {

          upError[key] = "";
          upFlag[key] = false;
        }
      }
      if ((key === 'file' && upData['type'] === 'file')) {
        if (!Yup.mixed().required().isValidSync(upData[key])) {
          flag = false;
          upError[key] = "This Field is required";
          upFlag[key] = true;
        } else {

          upError[key] = "";
          upFlag[key] = false;
        }
      }
      // if ((key === 'description' && upData['type'] === 'file')) {
      //   if (!Yup.mixed().required().isValidSync(upData[key])) {
      //     flag = false;
      //     upError[key] = "This Field is required";
      //     upFlag[key] = true;
      //   } else {

      //     upError[key] = "";
      //     upFlag[key] = false;
      //   }
      // }
    });
    setUploadErr({ ...upError });
    setUploadFlag({ ...upFlag });

    return flag;
  }



  const handleConfirmUpload = async () => {
    const isValid = await validateUploadField();
    if (isValid) {
      let dataArray;
      let fileArray;
      let data;
      if (upload.upload === 'clue') {
        dataArray = [...values.clues];
        fileArray = [...values.clueFiles]
        if (upload.type === 'file') {
          data = {
            title: upload.title,
            description: upload.description,
            preview: upload.preview,
            fileType: upload.fileType,
            points: upload.points,
            cluesType: upload.type,
            fileIndex: fileArray.length
          }
          dataArray.push(data);
          fileArray.push(upload.file)
        }
        if (upload.type === 'text') {
          data = {
            title: upload.title,
            text: upload.text,
            points: upload.points,
            cluesType: upload.type
          }
          dataArray.push(data);
        }
        setValues({
          ...values,
          ['clues']: dataArray,
          ['clueFiles']: fileArray
        })
      }
      if (upload.upload === 'hint') {
        dataArray = [...values.hints];
        fileArray = [...values.hintFiles]
        if (upload.type === 'file') {
          data = {
            title: upload.title,
            description: upload.description,
            preview: upload.preview,
            points: upload.points,
            fileType: upload.fileType,
            hintsType: upload.type,
            fileIndex: fileArray.length
          }
          dataArray.push(data);
          fileArray.push(upload.file)
        }
        if (upload.type === 'text') {
          data = {
            title: upload.title,
            text: upload.text,
            points: upload.points,
            hintsType: upload.type
          }
          dataArray.push(data);
        }
        setValues({
          ...values,
          ['hints']: dataArray,
          ['hintFiles']: fileArray
        })
      }
      setUpload({
        ...upload,
        title: "",
        file: "",
        description: "",
        text: "",
        preview: "",
        points: "",
        type: "text",
        fileType: "",
        show: false,
      });

    }

  }


  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++
    Dynamic hints and clues handling Ends
    ++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  const UploadPuzzleSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').strict().trim("Name should not have trailing white spaces").min(1).max(60),
    // solution: Yup.string().required("Solution is required"),
    solutionPoints: Yup.string().matches(/^[0-9]{1,3}$/, 'Please enter valid Points'),
    // minutes: Yup.string().required("Minutes is required").matches(/^[0-9]+$/,'Please enter Only digits [1-59]').min(1).max(59),
    // seconds: Yup.string().required("Seconds is required").matches(/^[0-9]+$/,'Please enter Only digits [0-59]').min(0).max(59),
    // description: Yup.string().max(500,"Description maximum length reached(500 characters)"),
    // puzzle: Yup.mixed().when("puzzleType",{
    //   is:"file",
    //   then : Yup.string().required("Puzzle is required")
    // }),
    // puzzleText: Yup.string().required('Puzzle Text is required'),
    // clues: Yup.array().min(1,"Clues is Required"),
    // hints: Yup.array().min(1,"Hints is required")
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      gameId: gameId,
      name: "",
      solution: [],
      solutionPoints: "",
      solutionDescription: "",
      description: "",
      puzzle: "",
      puzzlePreview: null,
      puzzleType: "file",
      puzzleText: "",
      clues: [],
      hints: [],
      hintFiles: [],
      clueFiles: []
    },

    validationSchema: UploadPuzzleSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values)
      try {

        const formData = new FormData();

        formData.append('gameId', values.gameId);
        formData.append('name', values.name.trim());
        formData.append('solution', JSON.stringify(values.solution));
        formData.append('solutionPoints', values.solutionPoints);
        formData.append('description', values.description.trim());
        formData.append('solutionDescription', values.solutionDescription.trim());

        let hintsData = [];
        let cluesData = [];

        for (let key in values.hints) {
          if (values.hints[key].hintsType === 'file') {
            const appendData = {
              title: values.hints[key].title,
              points: values.hints[key].points,
              hintsType: values.hints[key].hintsType,
              description: values.hints[key].description
            }
            hintsData.push(appendData);
          } else {
            hintsData.push(values.hints[key]);
          }
        }
        formData.append('hints', JSON.stringify(hintsData));
        for (let key in values.clues) {
          if (values.clues[key].cluesType === 'file') {
            const appendData = {
              title: values.clues[key].title,
              points: values.clues[key].points,
              cluesType: values.clues[key].cluesType,
              description: values.clues[key].description
            }
            cluesData.push(appendData);
          } else {
            cluesData.push(values.clues[key]);
          }
        }
        formData.append('clues', JSON.stringify(cluesData));

        if (values.puzzleType === 'text') {
          formData.append('puzzle', values.puzzleText);
        }

        if (values.puzzleType === 'file') {
          formData.append('puzzle', values.puzzle);
        }

        for (let key in values.clueFiles) {
          formData.append('clueFiles', values.clueFiles[key]);
        }
        for (let key in values.hintFiles) {
          formData.append('hintFiles', values.hintFiles[key]);
        }

        const chapterData = await ChapterAdd(formData);

        if (chapterData.status === 200) {
          // navigate('/admin/gamelist');
          setShowChapter(false);
          refreshList()
          toast.success(chapterData?.data.message);
        }

      } catch (error) {
        console.log(error)
        toast.error(error?.response.data.message);

      }
    }
  });


  const { errors, values, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps, setValues } = formik;


  const handleDropPuzzle = (e) => {
    let p = e.target.files[0];
    // console.log(p);
    // console.log(p.type.split("/")[1])
    if (allowed_ext.includes(p.type.split("/")[1]) || allowedFileType.includes(p.type.split("/")[0])) {
      setFieldValue('puzzle', p);
      setFieldValue('puzzleFile', toString(p.name));
      setFieldValue('puzzlePreview', {
        mediaType: p.type,
        fileType: 'uploaded',
        url: URL.createObjectURL(p)
      });
      if (p.type.split("/")[0] === "video") {
        setFieldValue('solution', [])
      }

    } else {
      setFieldValue('puzzle', "");
      setFieldValue('puzzlePreview', null);
      toast.error(`Only ${allowed_ext} are allowed`)
    }

  };


  const handlePuzzleText = (e) => {
    setValues({
      ...values,
      puzzleText: e.target.value
    })

  }



  const [solution, setSolution] = useState({
    show: false,
    data: [],
    error: [false],
    errorMessage: [""]
  })
  console.log("vlur", values.solution.length)
  const handleOpenSolution = () => {
    if (values.solution.length >= 1) {
      setSolution({
        ...solution,
        show: true,
        data: [...values.solution]
      })
    }
    else {

      setSolution({
        ...solution,
        show: true,
        data: [...values.solution, ""]
      })
    }
  }

  const handleCloseSolution = () => {
    setSolution({
      ...solution,
      show: false,
      data: [],
      errorMessage: [""]
    })
  }

  const handleChangeSolution = (e, i) => {
    let solArr = [...solution.data];
    let val = e.target.value;
    solArr[i] = val;
    setSolution({
      ...solution,
      data: solArr
    })
  }

  const validateSolution = () => {
    let solArr = [...solution.data];
    let errArr = [...solution.error];
    let errMsgArr = [...solution.errorMessage];
    let flag = true;
    solArr.map((item, i) => {
      if (!Yup.string().required().isValidSync(item)) {
        flag = false;
        errMsgArr[i] = "This Field is required";
        errArr[i] = true;
      } else if (!Yup.string().strict().trim().isValidSync(item)) {
        flag = false;
        errMsgArr[i] = "Trailing white spaces are not allowed";
        errArr[i] = true;
      } else if (!Yup.string().max(60).isValidSync(item)) {
        flag = false;
        errMsgArr[i] = "This field can only contain maximum 60 characters";
        errArr[i] = true;
      }

    })
    setSolution({
      ...solution,
      error: errArr,
      errorMessage: errMsgArr
    })
    return flag;
  }

  const AddSolution = () => {
    let solArr = [...solution.data];
    let errArr = [...solution.error];
    let errMsgArr = [...solution.errorMessage];
    let flag = validateSolution();

    if (flag) {
      solArr.push("");
      errArr.push(false);
      errMsgArr.push("");
      setSolution({
        ...solution,
        data: solArr,
        error: errArr,
        errorMessage: errMsgArr
      })
    }
  }

  const RemoveSolution = (i) => {
    let solArr = [...solution.data];
    solArr.splice(i, 1);
    setSolution({
      ...solution,
      data: solArr
    })
  }

  const handleConfirmSolution = () => {
    let flag = validateSolution();

    if (flag) {
      setFieldValue('solution', [...solution.data]);

      setSolution({
        ...solution,
        show: false,
        data: []
      })
    }
  }


  /* Drag and drop hints */

  function onDragEndHints(result) {
    console.log(result);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newValue = reorder(
      values.hints,
      result.source.index,
      result.destination.index
    );

    setValues({ ...values, hints: [...newValue] });
  }


  /* Drag and drop clues */

  function onDragEndClues(result) {
    console.log(result);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newValue = reorder(
      values.clues,
      result.source.index,
      result.destination.index
    );

    setValues({ ...values, clues: [...newValue] });
  }

  return (
    <>
      <Modal show={solution.show} style={{ padding: "15px" }} centered backdrop="static">
        <ModalBody>
          <ModalBody>
            <Grid container>
              <Grid item lg={12} md={12}>
                <Box display="flex" mb={2} justifyContent="space-between">
                  <h4 style={{ marginBottom: '20px' }}>Enter Possible Solutions</h4>
                  <Tooltip title="Add More Solution">
                    <AddCircleOutlinedIcon onClick={() => { AddSolution() }} fontSize='large' />
                  </Tooltip>
                </Box>
              </Grid>
              {solution.data.map((element, i) => (
                <Grid key={i} item lg={12} md={12}>
                  <Box display="flex" mb={2} justifyContent="space-between">
                    <TextField
                      variant="outlined"
                      required
                      name="solution"
                      placeholder='Enter Solution'
                      onChange={(e) => { handleChangeSolution(e, i) }}
                      value={element}
                      error={Boolean(solution.error[i] && solution.errorMessage[i])}
                      helperText={solution.error[i] && solution.errorMessage[i]}
                    />
                    {solution.data.length &&
                      <Tooltip title="Remove Solution">
                        <RemoveCircleIcon fontSize='large' onClick={() => { RemoveSolution(i) }} />
                      </Tooltip>
                    }
                  </Box>
                </Grid>
              ))}
            </Grid>
            <DialogActions>
              <Button onClick={() => { handleCloseSolution() }} className="btn btn-outline-secondary btn btn-primary">CANCEL</Button>
              <Button onClick={() => { handleConfirmSolution() }} className="btn btn-primary">SAVE</Button>
            </DialogActions>
          </ModalBody>
        </ModalBody>
      </Modal>
      <Modal show={del.show} style={{ padding: "15px" }} centered backdrop="static">
        <ModalBody>
          <ModalTitle as="h5">Confirm remove</ModalTitle>
          <ModalBody>
            <Typography variant='subtitle1'>Are You sure you want to remove this {del.type}?</Typography>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => { handleCancelDel() }} variant="outlined" className="btn btn-outline-secondary">CANCEL</Button>
            <Button onClick={() => { handleConfirmDel() }} className="btn btn-primary">CONFIRM</Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
      <Modal show={upload.show} style={{ padding: "15px" }} centered backdrop="static">
        <ModalBody>
          <h4 style={{ marginBottom: '20px', textTransform: 'capitalize' }}>{`${upload.upload}`}</h4>

          <Grid container spacing={3} mt={3}>
            <Grid item lg={6} md={12}>
              <InputLabel>Title*</InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                required
                name="title"
                placeholder='Enter Title'
                onChange={(e) => { handleChangeUpload(e) }}
                value={upload.title}
                error={Boolean(uploadFlag.title && uploadErr.title)}
                helperText={uploadFlag.title && uploadErr.title}
              />
            </Grid>
            {upload.upload === 'hint' && (
              <>
                <Grid item lg={6} md={12}>
                  <InputLabel>Points*</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    required
                    type="number"
                    name="points"
                    placeholder='Enter Points'
                    onChange={(e) => { handleChangeUpload(e) }}
                    value={upload.points}
                    error={Boolean(uploadFlag.points && uploadErr.points)}
                    helperText={uploadFlag.points && uploadErr.points}
                  />
                </Grid>
              </>
            )}
            <Grid item lg={6} xs={12}>
              <InputLabel>Type*</InputLabel>
              <FormControl error={Boolean(uploadFlag.type && uploadErr.type)}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={upload.type}
                  defaultValue={upload.type}
                  name="type"
                  onChange={(e) => { handleChangeUpload(e) }}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <Grid container>
                    <Grid item lg={6} xs={12}>
                      <FormControlLabel value="file" control={<Radio />} label="File" />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <FormControlLabel value="text" control={<Radio />} label="Text" />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
              <FormHelperText style={{ color: 'red' }}>{uploadFlag.type && uploadErr.type}</FormHelperText>
            </Grid>
            {upload.type === 'file' &&
              <Grid item xs={12} md={12}>
                <InputLabel>File*</InputLabel>
                <input type="file" name="file" value="" className="form-control" onChange={(e) => { handleDropFiles(e) }} style={{ width: '99.5%', marginBottom: "5px" }} />
                {upload.fileType && upload.preview &&
                  <EditPuzzleDisplay displayPuzzle={{ mediaType: upload?.fileType, url: upload.preview, fileType: 'uploaded' }} />
                }
                {uploadFlag.file && <small className="text-danger form-text">{uploadFlag.file && uploadErr.file}</small>}
              </Grid>
            }
            {upload.type === 'text' &&
              <Grid item xs={12} md={12}>
                <InputLabel>Text*</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  name="text"
                  multiline
                  minRows={1}
                  maxRows={5}
                  placeholder='Enter Text'
                  value={upload.text}
                  error={Boolean(uploadFlag.text && uploadErr.text)}
                  helperText={uploadFlag.text && uploadErr.text}
                  onChange={(e) => { handleChangeUpload(e) }} />
              </Grid>
            }
            {upload.type === 'file' &&
              <Grid item xs={12} md={12}>
                <InputLabel>Description</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  name="description"
                  multiline
                  minRows={1}
                  maxRows={5}
                  placeholder='Enter Description'
                  value={upload.description}
                  // error={Boolean(uploadFlag.description && uploadErr.description)}
                  // helperText={uploadFlag.description && uploadErr.description}
                  onChange={(e) => { handleChangeUpload(e) }} />
              </Grid>
            }
          </Grid>
          <DialogActions>
            <MButton onClick={() => { handleCloseUpload() }} variant="outlined" className="btn btn-outline-secondary">Cancel</MButton>
            <MButton onClick={() => { handleConfirmUpload() }} className="btn btn-primary">Confirm</MButton>
          </DialogActions>
        </ModalBody>
      </Modal>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <InputLabel>Name*</InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                required
                placeholder='Enter Name'
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                {...getFieldProps('name')} />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputLabel>Chapter Description</InputLabel>
              <TextField
                fullWidth
                placeholder='Enter Description'
                variant="outlined"
                value={values.description}
                required
                multiline
                minRows={3}
                maxRows={5}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                {...getFieldProps('description')} />
            </Grid>
            {(values.puzzle === "" || (values.puzzle.type.split("/")[0] !== "video") || values.puzzleType === "text") && <Grid item xs={12} md={6}>
              <InputLabel>Possible Solution</InputLabel>
              {/* <TextField 
                fullWidth
                variant="outlined" 
                required
                placeholder='Enter Solution'
                value={values.solution}
                error={Boolean(touched.solution && errors.solution)}
                helperText={touched.solution && errors.solution}
                {...getFieldProps('solution')} />             */}

              <MButton
                variant='contained'
                color="primary"
                startIcon={<AddCircleOutlinedIcon />}
                onClick={() => { handleOpenSolution() }}
              >
                Add Possible Solutions
              </MButton>
              <Stack flexDirection="row" display="flex" flexWrap="wrap">
                {values.solution[0] !== "" && values.solution.map((item, i) => (
                  <Chip key={i} label={item} style={{ margin: "5px" }} />
                ))}
              </Stack>

            </Grid>}
            {/* <Grid item xs={12} md={6}>
              <InputLabel>Reveal Answer Penalty</InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                type='number'
                required
                placeholder='Enter Reveal Answer Penalty'
                value={values.solutionPoints}
                error={Boolean(touched.solutionPoints && errors.solutionPoints)}
                helperText={touched.solutionPoints && errors.solutionPoints}
                {...getFieldProps('solutionPoints')} />
            </Grid> */}
            {(values.puzzle === "" || (values.puzzle.type.split("/")[0] !== "video") || values.puzzleType === "text") && <Grid item xs={12} md={12}>
              <InputLabel>Solution Description</InputLabel>
              <TextField
                fullWidth
                placeholder='Enter Description'
                variant="outlined"
                value={values.solutionDescription}
                required
                multiline
                minRows={3}
                maxRows={5}
                error={Boolean(touched.solutionDescription && errors.solutionDescription)}
                helperText={touched.solutionDescription && errors.solutionDescription}
                {...getFieldProps('solutionDescription')} />
            </Grid>}
            <Grid item xs={12} lg={6}>
              <InputLabel id="demo-radio-buttons-group-label">Puzzle Type</InputLabel>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="file"
                  name="puzzleType"
                  style={{ display: 'flex', flexDirection: 'row' }}
                  {...getFieldProps('puzzleType')}
                >
                  <FormControlLabel value="file" control={<Radio />} label="File" />
                  <FormControlLabel value="text" control={<Radio />} label="Text" />
                </RadioGroup>
              </FormControl>

            </Grid>
            {values.puzzleType === 'file' &&
              <Grid item xs={12} md={6}>
                <InputLabel>Puzzle File</InputLabel>

                <input type="file" className="form-control" style={{ marginBottom: '4px' }} value="" name="puzzle" onChange={handleDropPuzzle} />

                {Boolean(touched.puzzle && errors.puzzle) && <small className="text-danger form-text">{touched.puzzle && errors.puzzle}</small>}
                {values.puzzlePreview !== null &&
                  <EditPuzzleDisplay displayPuzzle={values.puzzlePreview} />
                }
              </Grid>
            }
            {values.puzzleType === 'text' &&
              <Grid item xs={12} md={6}>
                <InputLabel>Puzzle Text</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  placeholder='Enter Puzzle Text'
                  value={values.puzzleText}
                  error={Boolean(touched.puzzleText && errors.puzzleText)}
                  helperText={touched.puzzleText && errors.puzzleText}
                  onChange={(e) => { handlePuzzleText(e) }} />

              </Grid>}
            <Grid item lg={12} xs={12}>
              <InputLabel>Hints</InputLabel>
              <Box spacing={2} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Grid item lg={12} xs={6}>
                  <MButton
                    variant='contained'
                    color="primary"
                    startIcon={<AddCircleOutlinedIcon />}
                    onClick={() => { handleOpenUpload('hint') }}
                  >Add Hints</MButton>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Reveal Answer Penalty</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type='number'
                    required
                    placeholder='Enter Reveal Answer Penalty'
                    value={values.solutionPoints}
                    error={Boolean(touched.solutionPoints && errors.solutionPoints)}
                    helperText={touched.solutionPoints && errors.solutionPoints}
                    {...getFieldProps('solutionPoints')} />
                </Grid>
              </Box>

              <FormHelperText style={{ color: 'red' }}>{touched.hints && errors.hints}</FormHelperText>
              <DragDropContext onDragEnd={onDragEndHints}>
                <Droppable direction='vertical' droppableId="list">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {values?.hints && Array.isArray(values?.hints) && values?.hints?.length !== 0 ?
                        <TableContainer component={Paper} style={{ margin: '20px 0', borderRadius: 0 }}>
                          <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Reorder</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Points</TableCell>
                                <TableCell>Hint</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody style={{ cursor: 'default' }}>
                              {values.hints.map((element, idx) => (
                                <Draggable key={idx} draggableId={idx.toString()} index={idx}>
                                  {(provided) => (
                                    <TableRow
                                      key={idx}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <TableCell>
                                        <OpenWithIcon style={{ cursor: 'grab' }} />
                                      </TableCell>
                                      <TableCell>{element.title}</TableCell>
                                      <TableCell>{element.description !== "" && element.description !== null && element.description !== undefined ? element.description : '-'}</TableCell>
                                      <TableCell>{element.points}</TableCell>
                                      <TableCell>
                                        {element.hintsType === 'text' && element.text}
                                        {element.hintsType === 'file' &&
                                          element.fileType && (element.fileType).split("/")[0] === "video" ?
                                          <video
                                            preload="auto"
                                            controls
                                            controlsList="nodownload"
                                            style={{
                                              top: 8,
                                              borderRadius: 1,
                                              // border:"2px solid #000",              
                                              objectFit: 'contain',
                                              // padding: '5px',
                                              height: '75px'
                                            }}
                                          >
                                            <source src={element.preview ? element.preview : element.url}></source>
                                          </video>
                                          : element.fileType && (element.fileType).split("/")[0] === "image" ?
                                            <Box
                                              component="img"
                                              alt="file preview"
                                              src={element.preview ? element.preview : element.url}
                                              sx={{
                                                top: 8,
                                                borderRadius: 1,
                                                // border:"2px solid #000",              
                                                objectFit: 'contain',
                                                // padding: '5px',
                                                height: '75px'
                                              }}
                                            />

                                            : element.fileType && (element.fileType).split("/")[0] === "audio" ?
                                              <Grid key={idx} item lg={8} md={12} xs={12}>
                                                <div id="hint-need-audio">
                                                  <AudioPlayerAdmin src={element.preview ? element.preview : element.url} />
                                                </div>
                                              </Grid>
                                              : <></>
                                        }
                                        {element.hintsType === 'file' &&
                                          element.mediaType && (element.mediaType).split("/")[0] === "video" ?
                                          <video
                                            preload="auto"
                                            controls
                                            controlsList="nodownload"
                                            style={{
                                              top: 8,
                                              borderRadius: 1,
                                              // border:"2px solid #000",              
                                              objectFit: 'contain',
                                              // padding: '5px',
                                              // width: 'calc(100% - 2px)',
                                              height: '75px'
                                            }}
                                          >
                                            <source src={element.preview}></source>
                                          </video>
                                          : element.mediaType && (element.mediaType).split("/")[0] === "image" ?
                                            <Box
                                              component="img"
                                              alt="file preview"
                                              src={element.preview}
                                              sx={{
                                                top: 8,
                                                borderRadius: 1,
                                                // border:"2px solid #000",              
                                                objectFit: 'contain',
                                                // padding: '5px',
                                                // width: 'calc(100% - 2px)',
                                                height: '75px'
                                              }}
                                            />

                                            : element.mediaType && (element.mediaType).split("/")[0] === "audio" ?
                                              <Grid key={idx} item lg={8} md={12} xs={12}>
                                                <div id="hint-need-audio">
                                                  <AudioPlayerAdmin src={element.preview} />
                                                </div>
                                              </Grid>
                                              : <></>
                                        }
                                      </TableCell>
                                      <TableCell>
                                        {/* <Tooltip title="Edit Hint">
                                            <EditIcon onClick={() => { handleOpenUpload('hint', idx, element.fileIndex, element) }} style={{ cursor:"pointer"}} />
                                          </Tooltip> */}
                                        <Tooltip title="Delete Hint">
                                          <DeleteIcon className='dlt-icon' onClick={() => { handleOpenDel('hint', idx, element.fileIndex) }} style={{ cursor: 'pointer' }} />
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Draggable>

                              ))}

                            </TableBody>
                          </Table>
                        </TableContainer> : ''}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <InputLabel style={{ marginTop: '8px' }}>Clues</InputLabel>
              <MButton
                variant='contained'
                color="primary"
                startIcon={<AddCircleOutlinedIcon />}
                onClick={() => { handleOpenUpload('clue') }}
              >Add Clues</MButton>
              <FormHelperText style={{ color: 'red' }}>{touched.clues && errors.clues}</FormHelperText>
              <DragDropContext onDragEnd={onDragEndClues}>
                <Droppable direction='vertical' droppableId="list">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {values.clues && Array.isArray(values?.clues) && values?.clues?.length !== 0 ?
                        <TableContainer component={Paper} style={{ margin: '20px 0', borderRadius: 0 }}>
                          <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Reorder</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Clues</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody style={{ cursor: 'default' }}>
                              {values.clues.map((element, idx) => (
                                <Draggable key={idx} draggableId={idx.toString()} index={idx}>
                                  {(provided) => (
                                    <TableRow
                                      key={idx}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <TableCell>
                                        <OpenWithIcon style={{ cursor: 'grab' }} />
                                      </TableCell>
                                      <TableCell>{element.title}</TableCell>
                                      <TableCell>{element.description !== "" && element.description !== null && element.description !== undefined ? element.description : '-'}</TableCell>
                                      <TableCell>
                                        {element.cluesType === 'text' && element.text}
                                        {element.cluesType === 'file' &&
                                          element.fileType && (element.fileType).split("/")[0] === "video" ?
                                          <video
                                            preload="auto"
                                            controls
                                            controlsList="nodownload"
                                            style={{
                                              top: 8,
                                              borderRadius: 1,
                                              // border:"2px solid #000",              
                                              objectFit: 'contain',
                                              // padding: '5px',
                                              height: '75px'
                                            }}
                                          >
                                            <source src={element.preview ? element.preview : element.url}></source>
                                          </video>
                                          : element.fileType && (element.fileType).split("/")[0] === "image" ?
                                            <Box
                                              component="img"
                                              alt="file preview"
                                              src={element.preview ? element.preview : element.url}
                                              sx={{
                                                top: 8,
                                                borderRadius: 1,
                                                // border:"2px solid #000",              
                                                objectFit: 'contain',
                                                // padding: '5px',
                                                height: '75px'
                                              }}
                                            />

                                            : element.fileType && (element.fileType).split("/")[0] === "audio" ?
                                              <Grid key={idx} item lg={8} md={12} xs={12}>
                                                <div id="hint-need-audio">
                                                  <AudioPlayerAdmin src={element.preview ? element.preview : element.url} />
                                                </div>
                                              </Grid>
                                              : <></>
                                        }
                                        {element.cluesType === 'file' &&
                                          element.mediaType && (element.mediaType).split("/")[0] === "video" ?
                                          <video
                                            preload="auto"
                                            controls
                                            controlsList="nodownload"
                                            style={{
                                              top: 8,
                                              borderRadius: 1,
                                              // border:"2px solid #000",              
                                              objectFit: 'contain',
                                              // padding: '5px',
                                              // width: 'calc(100% - 2px)',
                                              height: '75px'
                                            }}
                                          >
                                            <source src={element.preview}></source>
                                          </video>
                                          : element.mediaType && (element.mediaType).split("/")[0] === "image" ?
                                            <Box
                                              component="img"
                                              alt="file preview"
                                              src={element.preview}
                                              sx={{
                                                top: 8,
                                                borderRadius: 1,
                                                // border:"2px solid #000",              
                                                objectFit: 'contain',
                                                // padding: '5px',
                                                // width: 'calc(100% - 2px)',
                                                height: '75px'
                                              }}
                                            />

                                            : element.mediaType && (element.mediaType).split("/")[0] === "audio" ?
                                              <Grid key={idx} item lg={8} md={12} xs={12}>
                                                <div id="hint-need-audio">
                                                  <AudioPlayerAdmin src={element.preview} />
                                                </div>
                                              </Grid>
                                              : <></>
                                        }
                                      </TableCell>
                                      <TableCell>
                                        <Tooltip title="Delete Hint">
                                          <DeleteIcon className='dlt-icon' onClick={() => { handleOpenDel('clue', idx, element.fileIndex) }} style={{ cursor: 'pointer' }} />
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Draggable>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer> : ''}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
            <Button type="reset" onClick={() => { setShowChapter(false) }} className="btn btn-outline-secondary mr-3">
              BACK
            </Button>
            <Button type="submit">
              SAVE
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AddChapter;
