import React, { useContext, useEffect, useState } from 'react'
import clueIcon from "../../../assets/images/button-img-toggle.svg";
import { styled } from '@material-ui/styles';
import ClueViewer from './ClueViewer';
import audioIcon from "../../../assets/images/audio.png";
import imageIcon from "../../../assets/images/image.png"
import playIcon from "../../../assets/images/play.png";
import discributionIcon from "../../../assets/images/discribution.png"
import cancelIcon from "../../../assets/images/cancel.png"
import { SocketContext } from "../../../contexts/socketConnection";

const ContentStyle = styled('div')(() => ({
  backgroundColor: "#1D1C1C",
  color: "#FFF",
  padding: "0 10%",
  textAlign: "center",
}));


const ClueStyle = styled('div')(() => ({
  backgroundColor: "#000",
  color: "#FFF",
  padding: '20px 10px 20px',
  marginTop: "5%",
  border: "1px #FFF solid",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const Clues = React.memo(function Clues({ clueData, setShrink, labels, }) { //handleOpenHints, hintLable
  const [showDrawer, setShowDrawer] = useState(false);
  const socket = useContext(SocketContext);

  const [view, setView] = useState({
    show: false,
    data: null
  })

  useEffect(() => {
    socket.on("codeSuccess", (data) => {
      setShowDrawer(false);
      setShrink(false);
      setView({
        ...view,
        show: false,
        data: { ...data }
      })
    });
  }, [])

  const handleClickClue = (data) => {
    // setShowDrawer(false);
    console.log(data)
    setView({
      ...view,
      show: true,
      data: { ...data }
    })
  }
  const handleCloseView = () => {
    console.log("band ho rha hai")
    setView({
      ...view,
      show: false,
      data: null
    })
    setShrink(false);
    setShowDrawer(false);
  }

  // const Content = () => (
  //   <ContentStyle className='clues-side-bar'>
  //     <h3 className='clue-title'>{labels?.clueLabel}</h3>
  //     <div className='sidebar-scroll'>
  //       {clueData && clueData.length ? clueData.map((data, idx) => (
  //         <ClueStyle key={idx} onClick={() => { handleClickClue(data) }}>
  //           {data.mediaType && data.mediaType.split('/')[0] === 'video' ?
  //             <img src={playIcon} width="42px" style={{ cursor: 'pointer' }} />
  //             :
  //             data.mediaType && data.mediaType.split('/')[0] === 'audio' ?
  //               <img src={audioIcon} width="42px" style={{ cursor: 'pointer' }} />
  //               :
  //               data.mediaType && data.mediaType.split('/')[0] === 'image' ?
  //                 <img src={imageIcon} width="42px" style={{ cursor: 'pointer' }} />
  //                 :
  //                 <img src={discributionIcon} width="42px" style={{ cursor: 'pointer' }} />
  //           }
  //           <p className='clue-title'>{data.title}</p>
  //         </ClueStyle>

  //       )) :
  //         <></>}
  //     </div>
  //   </ContentStyle>
  // );


  return (
    <>
      <div className={showDrawer ? "button-aside close" : "button-aside"} style={{ zIndex: "100" }}>
        <p className='toggle-btn'>
          <span className='clue-btn slider-btn' onClick={() => { setShowDrawer(!showDrawer); setShrink(!showDrawer) }}><a className='hint-text'>{labels?.clueLabel}</a></span>

          {/* <span className='hint-btn slider-btn' onClick={()=>{handleOpenHints()}}><a className='hint-text'>{hintLable}</a></span> */}

        </p>


        {clueData && clueData.length ?
          // <Content />
          <ContentStyle className='clues-side-bar'>
            <h3 className='clue-title'>{labels?.clueLabel}</h3>
            <div className='sidebar-scroll'>
              {clueData && clueData.length ? clueData.map((data, idx) => (
                <ClueStyle key={idx} onClick={() => { handleClickClue(data) }}>
                  {data.mediaType && data.mediaType.split('/')[0] === 'video' ?
                    <img src={playIcon} width="42px" style={{ cursor: 'pointer' }} />
                    :
                    data.mediaType && data.mediaType.split('/')[0] === 'audio' ?
                      <img src={audioIcon} width="42px" style={{ cursor: 'pointer' }} />
                      :
                      data.mediaType && data.mediaType.split('/')[0] === 'image' ?
                        <img src={imageIcon} width="42px" style={{ cursor: 'pointer' }} />
                        :
                        <img src={discributionIcon} width="42px" style={{ cursor: 'pointer' }} />
                  }
                  <p className='clue-title'>{data.title}</p>
                </ClueStyle>
              )) :
                <></>}
            </div>
          </ContentStyle>
          :
          <div className="no-clues">
            <span className="no-clues-shape"><img src={cancelIcon} /></span>
            <span className="text-center mt-3">{labels?.nocluelabel}</span>
          </div>
        }
      </div>
      {view?.show &&
        <ClueViewer
          labels={labels}
          show={view?.show}
          data={view?.data}
          onClose={() => { handleCloseView() }}
        />}

    </>
  )
}
)

export default Clues;