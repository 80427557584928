// export const Base_URL = 'http://172.16.11.103:8001';
// export const sock_URL = 'http://172.16.11.103:8001';


export const Base_URL = 'https://puzzlegame.devtechnosys.tech:17147';
export const sock_URL = 'https://puzzlegame.devtechnosys.tech:17147';
// export const Base_URL = 'http://172.16.11.109:8001';
// export const sock_URL = 'http://172.16.11.109:8001';


//localhost
// export const Base_URL = 'http://172.16.11.19:8001';
// export const sock_URL = 'http://172.16.11.19:8001';