const base = "https://puzzlegame.devtechnosys.tech:17147"
// const base = 'http://172.16.11.109:8001';
// const base = "http:/172.16.11.42:3000";


// const base = "http://172.16.11.19:8001";


export const baseUrl = base + "/admin";
export const backendUrl = base + "/admin/access";
export const gameUrl = base + "/admin/games";
export const chapterUrl = base + "/admin/chapters";
export const contentUrl = base + "/admin/contents";
export const url = base + "/";
export const podUrl = base + "/admin/pods";
export const teamUrl = base + "/admin/teams";
export const dashboardUrl = base + "/admin/dashboard";
export const gameName = base + "/admin/";

// export const frontendUrl = 'https://192.168.1.163:3000/changePassword';
