import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { url } from '../../Config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { reorder } from '../../../../utils/dragMethods';
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  Card,
  InputLabel,
  Button as MButton,
  DialogActions,
  Typography,
  CardActions,
  FormHelperText,
  Tooltip,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell
} from '@material-ui/core';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';

import { getChapterOne } from '../../Services/ChapterServices';
import { editchapter } from '../../Services/ChapterServices';
import { toast } from 'react-toastify';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditClueDisplay from './EditClueDisplay';
import EditHintDisplay from './EditHintDisplay';
import EditPuzzleDisplay from './EditPuzzleDisplay';
import AudioPlayerAdmin from '../../../../components/AudioPlayerAdmin';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { RestoreFromTrash } from '@mui/icons-material';



const EditPuzzle = ({ editId, onClose, refreshList }) => {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const allowed_ext = ['png', 'jpg', 'jpeg', 'mp3', 'mp4', 'wav', 'aac', 'ogg', 'm4a', 'mpeg', 'mov'];
  const allowedFileType = ['image', 'audio', 'video'];
  const [hideSol, setHideSol] = useState(false);
  const [displayPuzzle, setDisplayPuzzle] = useState({});
  const [displayClues, setDisplayClues] = useState([]);
  const [displayHints, setDisplayHints] = useState([]);


  //!Upload Files Array 
  const [files, setFiles] = useState({});
  const [hintFiles, setHintFiles] = useState({});



  const [solution, setSolution] = useState({
    show: false,
    data: [],
    error: [false],
    errorMessage: [""]
  })

  const UploadPuzzleSchema = Yup.object().shape({
    name: Yup.string().required('name is required').strict().trim("Name should not have trailing white spaces").min(1).max(60),
    // puzzleText: Yup.string().required('Puzzle Text is required')
    // when("puzzleType", {
    //   is: "text",
    //   then: Yup.string().matches(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/, 'Please enter valid Text')
    // })
  });


  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++
  Dynamic hints and clues handling starts
  ++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  const [upload, setUpload] = useState({
    title: "",
    file: "",
    description: "",
    text: "",
    preview: "",
    points: "",
    type: "text",
    fileType: "",
    show: false,
    upload: '',
    fileIndex: null,
    index: 0
  })

  const [del, setDel] = useState({
    show: false,
    type: null,
    idx: null,
    fileIdx: null
  });

  const handleOpenDel = (type, idx, fileIdx) => {
    setDel({
      ...del,
      type: type,
      show: true,
      idx: idx,
      fileIdx: fileIdx
    });
  }

  const handleConfirmDel = () => {
    if (del.type === 'hint') {
      handleRemoveHintUpload(del.idx, del.fileIdx);
    }
    if (del.type === 'clue') {
      handleRemoveClueUpload(del.idx, del.fileIdx);
    }
    setDel({
      ...del,
      show: false,
      type: null,
      idx: null,
      fileIdx: null
    });

  }

  const handleCancelDel = () => {

    setDel({
      ...del,
      show: false,
      type: null,
      idx: null,
      fileIdx: null
    });

  }

  const [uploadErr, setUploadErr] = useState({
    title: "Title is required",
    file: "File is required",
    description: "Description is required",
    points: "Points is required",
    text: "Text is required",
    type: "Type is required",
    titleErr: false,
    fileErr: false,
    descriptionErr: false,
    pointsErr: false,
    textErr: false,
    typeErr: false
  });


  const handleOpenUpload = (upload, idx, fileIndex, data) => {
    console.log("upload", upload)
    console.log("idx", idx)
    console.log('fileIndex', fileIndex)
    console.log('data', data)
    if (data) {
      console.log(data)
      if (upload === 'hint') {
        setUpload({
          // ...upload,
          title: data.title,
          file: data.preview,
          description: data.description,
          text: data.text,
          preview: url + data.preview,
          filePreview: data.url ? url + data.url : data.preview,
          fileUrl: data.url,
          points: data.points,
          type: data.hintsType,
          fileType: data.mediaType ? data.mediaType : data.fileType,
          show: true,
          upload: upload,
          index: idx
        })
      } else if (upload === 'clue') {
        setUpload({
          // ...upload,
          title: data.title,
          file: data.preview,
          description: data.description,
          text: data.text,
          preview: url + data.preview,
          filePreview: data.url ? url + data.url : data.preview,
          fileUrl: data.url,
          fileType: data.mediaType ? data.mediaType : data.fileType,
          type: data.cluesType,
          show: true,
          upload: upload,
          index: idx
        })
      }
    } else {
      setUpload({
        // ...upload,
        show: true,
        upload: upload,
        index: idx
      })
      console.log("nhi mill rha")
    }
  }

  const handleCloseUpload = () => {
    setUpload({
      title: "",
      file: "",
      description: "",
      text: "",
      preview: "",
      points: "",
      type: "text",
      fileType: "",
      show: false,
      upload: ''
    })
  }

  const handleChangeUpload = (e) => {
    setUpload({
      ...upload,
      [e.target.name]: e.target.value
    });
    setUploadErr({
      ...uploadErr,
      [e.target.name]: '',
      [e.target.name + 'Err']: false
    })
  }

  //old
  // const handleDropFiles = (e) => {
  //   let inputFile = e.target.files[0];
  //   let inputType = e.target.files[0].type;
  //   let inputURL = URL.createObjectURL(inputFile);
  //   // console.log(inputType)allowed_ext;

  //   setUpload({
  //     ...upload,
  //     file: inputFile,
  //     preview: inputURL,
  //     filePreview: inputURL,
  //     fileType: inputType
  //   })
  //   console.log("***************", { upload })
  //   setFiles([...files, {
  //     ...upload,
  //     file: inputFile,
  //     preview: inputURL,
  //     filePreview: inputURL,
  //     fileType: inputType
  //   }]);

  //   setUploadErr({
  //     ...uploadErr,
  //     fileErr: false,
  //   })

  // };

  // const [files, setFiles] = useState([]);
  // const handleDropFiles = (e) => {
  //   let inputFile = e.target.files[0];
  //   let inputType = e.target.files[0].type;
  //   let inputURL = URL.createObjectURL(inputFile);

  //   let updatedUpload = {
  //     ...upload,
  //     file: inputFile,
  //     preview: inputURL,
  //     filePreview: inputURL,
  //     fileType: inputType,
  //   };

  //   setUpload(updatedUpload);

  //   let newFilesArray = [...files]; // Create a new array to avoid modification of state directly

  //   // If index already is in files, remove that index object and re-add new one
  //   if (updatedUpload.index) {
  //     const existingIndex = newFilesArray.findIndex((file) => file.index === updatedUpload.index);
  //     if (existingIndex !== -1) {
  //       console.log("No index")
  //       newFilesArray[existingIndex] = updatedUpload;
  //     } else {
  //       newFilesArray.push(updatedUpload);
  //     }
  //   } else {
  //     newFilesArray.push(updatedUpload);
  //   }

  //   setFiles(newFilesArray);

  //   setUploadErr({
  //     ...uploadErr,
  //     fileErr: false,
  //   });
  // };






  const handleRemoveHintUpload = (idx, fileIdx) => {
    let Elements = [...values.hints]
    // let Files = [...values.hintFiles];
    Elements[idx].deleteStatus = true;
    console.table(values.hints)
    console.table(values.hintFiles)
    // Elements.splice(idx, 1);
    // Files.splice(fileIdx, 1);
    setValues({
      ...values,
      hints: Elements,
      // hintFiles: Files,
    })
  }

  const handleRemoveClueUpload = (idx, fileIdx) => {
    let Elements = [...values.clues]
    let Files = [...values.clueFiles];
    Elements.splice(idx, 1);
    Files.splice(fileIdx, 1);
    setValues({
      ...values,
      clues: Elements,
      clueFiles: Files,
    })
  }


  const validateUpload = () => {
    let flag = true;
    let err = { ...uploadErr }

    if (upload.title === "" || upload.title === null || upload.title === undefined) {

      err['title'] = "Title is required"
      err['titleErr'] = true
      flag = false
    } else
      // if (!Yup.string().strict().trim("No spaces are allowed!").min(3).isValidSync(upload.title)) {
      //   flag = false;
      //   err['title'] = "This field must contain at least 3 characters with no trailing spaces!";
      //   err['titleErr'] = true;
      // }
      if (upload.type === "" || upload.type === null || upload.type === undefined) {
        err['type'] = "Type is required"
        err['typeErr'] = true
        flag = false
      }
    if (upload.upload === 'hint') {
      if (upload.points === "" || upload.points === null || upload.points === undefined) {
        err['points'] = "Points is required"
        err['pointsErr'] = true
        flag = false
      }
    }
    console.log(upload);
    if (upload.type === "file") {
      if ((upload.file === "" || upload.file === null || upload.file === undefined)) {
        if ((upload.filePreview === "" || upload.filePreview === null || upload.filePreview === undefined)) {
          err['file'] = "File is required"
          err['fileErr'] = true
          flag = false
        } else {
          err['fileErr'] = false;
        }
      } else {
        err['fileErr'] = false;
      }
      // if (upload.description === "" || upload.description === null || upload.description === undefined) {
      //   err['description'] = "Description is required"
      //   err['descriptionErr'] = true
      //   flag = false
      // }
    }
    if (upload.type === "text") {
      if (upload.text === "" || upload.text === null || upload.text === undefined) {
        err['text'] = "Text is required"
        err['textErr'] = true
        flag = false
      }
      // else
      //   if (!Yup.string().strict().trim().min(3).isValidSync(upload.text)) {
      //     flag = false;
      //     err['text'] = "This field must contain at least 3 characters with no trailing spaces!";
      //     err['textErr'] = true;
      //   }
    }
    setUploadErr({
      ...uploadErr,
      ...err
    })
    return flag;
  }



  const handleConfirmUpload = () => {
    const isValid = validateUpload();
    if (isValid) {
      let dataArray;
      let fileArray;
      let data;
      if (upload.upload === 'clue') {
        dataArray = [...values.clues];
        fileArray = [...values.clueFiles]
        if (upload.type === 'file') {
          data = {
            title: upload.title,
            description: upload.description,
            preview: upload.filePreview,
            fileType: upload.fileType,
            points: upload.points,
            cluesType: upload.type,
            url: upload.fileUrl,
            fileIndex: fileArray.length
          }
          console.log(data);
          if (upload.index !== null && upload.index !== undefined) {
            console.log("2", upload.index);
            // if(upload.fileIndex!==null && upload.fileIndex!==undefined){
            //   console.log("3",upload.fileIndex);
            // }
            fileArray[upload.fileIndex] = upload.file;
            dataArray[upload.index] = data;
          } else {
            console.log("4");
            dataArray.push(data);
            fileArray.push(upload.file)
          }
        }
        if (upload.type === 'text') {
          data = {
            title: upload.title,
            text: upload.text,
            points: upload.points,
            cluesType: upload.type
          }
          if (upload.index !== null && upload.index !== undefined) {
            console.log("2", upload.index);
            if (upload.fileIndex !== null && upload.fileIndex !== undefined) {
              console.log("3", upload.fileIndex);
              fileArray.splice(upload.fileIndex, 1);
            }
            dataArray[upload.index] = data;
          } else {
            dataArray.push(data);
          }
        }
        setValues({
          ...values,
          ['clues']: dataArray,
          ['clueFiles']: fileArray
        })
      }
      if (upload.upload === 'hint') {
        dataArray = [...values.hints];
        fileArray = [...values.hintFiles]
        if (upload.type === 'file') {
          console.log("1");
          data = {
            title: upload.title,
            description: upload.description,
            preview: upload.filePreview,
            points: upload.points,
            fileType: upload.fileType,
            hintsType: upload.type,
            url: upload.fileUrl,
            fileIndex: fileArray.length
          }
          if (upload.index !== null && upload.index !== undefined) {
            console.log("2", upload.index);
            // if(upload.fileIndex!==null && upload.fileIndex!==undefined){
            //   console.log("3",upload.fileIndex);
            // }
            fileArray[upload.fileIndex] = upload.file;
            dataArray[upload.index] = data;
          } else {
            console.log("4");
            dataArray.push(data);
            fileArray.push(upload.file)
          }
        }
        if (upload.type === 'text') {
          data = {
            title: upload.title,
            text: upload.text,
            points: upload.points,
            hintsType: upload.type
          }
          if (upload.index !== null && upload.index !== undefined) {
            console.log("2", upload.index);
            if (upload.fileIndex !== null && upload.fileIndex !== undefined) {
              console.log("3", upload.fileIndex);
              fileArray.splice(upload.fileIndex, 1);
            }
            dataArray[upload.index] = data;
          } else {
            dataArray.push(data);
          }
        }
        setValues({
          ...values,
          ['hints']: dataArray,
          ['hintFiles']: fileArray
        })
      }
      setUpload({
        ...upload,
        title: "",
        file: "",
        description: "",
        text: "",
        preview: "",
        points: "",
        type: "text",
        fileType: "",
        show: false,
        fileIndex: null,
        index: null
      });

    }

  }


  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++
    Dynamic hints and clues handling Ends
  ++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      gameId: gameId,
      id: editId,
      name: "",
      solution: [],
      solutionDescription: "",
      description: "",
      puzzleType: 'file',
      puzzle: "",
      puzzleText: "",
      clues: [],
      clueFiles: [],
      hintFiles: [],
      hints: []
    },

    validationSchema: UploadPuzzleSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values)
      try {
        const formData = new FormData();
        formData.append('_id', values.id);
        formData.append('gameId', values.gameId);
        formData.append('name', values.name.trim());
        formData.append('solution', JSON.stringify(values.solution));
        if (values.solutionPoints !== null && values.solutionPoints !== undefined && values.solutionPoints !== "") {
          formData.append('solutionPoints', values.solutionPoints);
        }
        formData.append('description', values.description.trim());
        formData.append('solutionDescription', values.solutionDescription.trim());

        let hintsData = [];
        let cluesData = [];
        console.log(values.hints)
        for (let key in values.hints) {
          console.log(values.hints);
          if (values.hints[key].hintsType === 'file') {
            const appendData = {
              _id: values.hints[key]._id,
              title: values.hints[key].title,
              description: values.hints[key].description,
              points: values.hints[key].points,
              hintsType: values.hints[key].hintsType,
              mediaType: values.hints[key].mediaType ? values.hints[key].mediaType : values.hints[key].fileType,
              url: values.hints[key].url,
              deleteStatus: values.hints[key].deleteStatus
            }
            hintsData.push(appendData);
          } else {
            hintsData.push(values.hints[key]);
          }
        }
        formData.append('hints', JSON.stringify(hintsData));
        for (let key in values.clues) {
          if (values.clues[key].cluesType === 'file') {
            const appendData = {
              _id: values.clues[key]._id,
              title: values.clues[key].title,
              description: values.clues[key].description,
              cluesType: values.clues[key].cluesType,
              mediaType: values.clues[key].mediaType ? values.clues[key].mediaType : values.clues[key].fileType,
              url: values.clues[key].url
            }
            cluesData.push(appendData);
          } else {
            cluesData.push(values.clues[key]);
          }
        }
        formData.append('clues', JSON.stringify(cluesData));
        if (values.puzzleType === 'text') {
          formData.append('puzzle', values.puzzleText);
        }
        if (values.puzzleType === 'file' && typeof (values.puzzle) !== 'string') {
          formData.append('puzzle', values.puzzle);
        }

        // for (let key in values.clueFiles) {
        //   formData.append(`clueFiles`, values.clueFiles[key]);
        // }

        // for (let key in values.hintFiles) {
        //   formData.append('hintFiles', values.hintFiles[key]);
        // }

        // files.forEach(function (file) {
        //   console.log("------+++++++++++=Clufiles", files)
        //   formData.append(`clueFiles`, file.file);
        // })

        Object.values(files).forEach(function (file) {
          console.log("------+++++++++++=Clufiles", files)
          formData.append(`clueFiles`, file.file);
        });

        Object.values(hintFiles).forEach(function (file) {
          console.log("------+++++++++++=Clufiles", files)
          formData.append(`hintFiles`, file.file);
        });



        const chapterData = await editchapter(formData);

        if (chapterData.status === 200) {
          // navigate('/admin/gamelist');
          onClose();
          refreshList()
          toast.success(chapterData?.data.message);
        }

      } catch (error) {
        console.log(error)
        toast.error(error?.response.data.message);

      }
    }
  });


  const { errors, values, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps, setValues } = formik;





  //!IMPORTANT FUNCTION FOR FILE UPLOAD ARRAY







  //!BACKUP FILE UPLOAD ARRAY

  const handleDropFiles = (e, type) => {
    let inputFile = e.target.files[0];
    let inputType = e.target.files[0].type;

    console.log({ inputFile })

    if (!inputType.includes("image/")) {
      handleFiles(inputFile, inputType)
      return;
    }

    if (inputFile) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result;
        const img = new Image();
        img.src = base64Data;

        img.onload = () => {
          handleFiles(inputFile, inputType)
        };

        img.onerror = () => {
          // The image is corrupted

          toast.error(`This image is corrupted please check your image`)
        };
      };
      reader.readAsDataURL(inputFile);
    }
  };



  const handleFiles = (inputFile, inputType) => {

    // The image is valid
    // toast.success(`Only ${allowed_ext} are allowed`)
    let inputURL = URL.createObjectURL(inputFile);

    let updatedUpload = {
      ...upload,
      file: inputFile,
      preview: inputURL,
      filePreview: inputURL,
      fileType: inputType,
    };





    setUpload(updatedUpload);
    let newFilesObject;

    if (updatedUpload.upload == "clue") {
      newFilesObject = { ...files }; // Create a new object to avoid modification of state directly
      // values.clues[updatedUpload.index] = updatedUpload
    } else if (updatedUpload.upload == "hint") {
      newFilesObject = { ...hintFiles };
      // values.hints[updatedUpload.index] = updatedUpload
    }

    if (typeof updatedUpload.index == 'number') {
      console.log("If condition", updatedUpload, newFilesObject)
      if (newFilesObject[updatedUpload.index]) {
        newFilesObject[updatedUpload.index] = updatedUpload;
      } else {
        let newKey = Object.keys(newFilesObject).length;
        newFilesObject[updatedUpload.index] = updatedUpload;
      }
    } else {
      /**
       * Getting Issue when Chapters are already 0 sorting is 0 and didn't update
       */

      if (updatedUpload.upload == "clue") {
        let newKey = Object.keys(displayClues).length == 0 ? Object.keys(files).length : Object.keys(displayClues).length
        console.log("else clue condition", displayClues, files, Object.keys(files).length, Object.keys(displayClues).length, newKey, updatedUpload, newFilesObject)
        newFilesObject[newKey] = updatedUpload;
        if (Object.keys(displayClues).length) {
          let obj = [...displayClues]
          obj[newKey] = updatedUpload
          setDisplayClues(obj)
        }
      } else if (updatedUpload.upload == "hint") {
        let newKey = Object.keys(displayHints).length == 0 ? Object.keys(hintFiles).length : Object.keys(displayHints).length
        newFilesObject[newKey] = updatedUpload;
        if (Object.keys(displayHints).length) {
          let obj = [...displayHints]
          obj[newKey] = updatedUpload
          setDisplayHints(obj)
        }
      }
    }

    let sortedFilesObject = Object.fromEntries(Object.entries(newFilesObject).sort());

    console.log("----------------values", values[`${updatedUpload.upload}s`])


    if (updatedUpload.upload == "clue") {
      setFiles(sortedFilesObject); // Create a new object to avoid modification of state directly
    } else if (updatedUpload.upload == "hint") {
      setHintFiles(sortedFilesObject);
    }
    setUploadErr({
      ...uploadErr,
      fileErr: false,
    });

  }



  const handleDropPuzzle = (e) => {
    let p = e.target.files[0];
    let puzzleUrl = URL.createObjectURL(p);
    if (allowed_ext.includes(p.type.split("/")[1]) || allowedFileType.includes(p.type.split("/")[0])) {
      setFieldValue('puzzle', p);
      setDisplayPuzzle(puzzleUrl);
      if (p.type.split("/")[0] === "video") {
        setHideSol(true);
        setFieldValue('solution', [])
      } else {
        setHideSol(false);
      }
    } else {
      toast.error(`Only ${allowed_ext} are allowed`)
    }

  };
  const handlePuzzleText = (e) => {
    setValues({
      ...values,
      puzzleText: e.target.value
    })
  }
  // const handleDropHints = (e) =>{
  //   let flag = [];
  //   let hints = e.target.files;
  //   let val = []
  //   Object.keys(hints).map((key)=>{

  //     if(allowed_ext.includes(hints[key].type.split("/")[1])){
  //       flag.push(true);
  //       val.push(hints[key])      
  //     }else{
  //       flag.push(false);
  //     }
  //   })
  //   if(flag){
  //     console.log(val);
  //     setFieldValue('hints',[...val]);
  //   }else{
  //     toast.error(`Only ${allowed_ext} are allowed`)
  //   }

  // };

  // const handleDropClues = (e) =>{
  //   let flag = false;
  //   let hints = e.target.files;
  //   let val = []
  //   Object.keys(hints).map((key)=>{
  //     console.log(allowed_ext.includes(hints[key].type.split("/")[1]))
  //     flag=true;
  //     val.push(hints[key])      
  //   })
  //   if(flag){
  //     console.log(val);
  //     setFieldValue('clues',[...val]);
  //   }


  // };

  const setSolutionValue = (data) => {
    let err = [];
    let errMsg = [];

    data.map(item => {
      err.push(false);
      errMsg.push("");
    });

    setSolution({
      ...solution,
      data: [...data],
      error: err,
      errorMessage: errMsg
    })
  }

  //get the chapter data for prefilled
  const getChapter = async () => {
    const resultChapter = await getChapterOne({ _id: editId });

    const data = resultChapter?.data.data;
    // console.log(data)
    // setVal({
    //   ...val,
    //   cluesText:[...data.cluesText],
    //   hintsText:[...data.hintsText],
    //   cluesTextErr:data.cluesText.map(()=>""),
    //   hintsTextErr:data.hintsText.map(()=>""),
    //   cluesTextFlag:data.cluesText.map(()=>false),
    //   hintsTextFlag:data.hintsText.map(()=>false),
    // })
    setDisplayPuzzle(data.puzzle);
    setDisplayClues(data.clues);
    setDisplayHints(data.hints);

    setSolutionValue(data.solution);
    setValues({
      ...values,
      name: data.name,
      solution: data.solution,
      solutionPoints: data.solutionPoints,
      solutionDescription: data.solutionDescription ? data.solutionDescription : "",
      description: data.description,
      disablePuzzleText: data.puzzle && data.puzzle.text ? false : true,
      puzzleText: data.puzzle?.text,
      puzzleType: data.puzzle && data.puzzle.text ? "text" : "file",
      clues: data.clues,
      hints: data.hints,
      puzzle: data.puzzle && data.puzzle.url ? data.puzzle.url : ""
    })

    if (data.puzzle && data.puzzle.mediaType && data.puzzle.mediaType.split("/")[0] === "video") {
      setHideSol(true);
    }
  };
  useEffect(() => {
    getChapter();
  }, []);

  const handleOpenSolution = () => {
    setSolution({
      ...solution,
      show: true,
      data: [...values.solution]
      // data: [...values.solution, ""]
    })
  }

  const handleCloseSolution = () => {
    setSolution({
      ...solution,
      show: false,
      data: [],
      errorMessage: [""]
    })
  }

  const handleChangeSolution = (e, i) => {
    let solArr = [...solution.data];

    let val = e.target.value;
    solArr[i] = val;

    setSolution({
      ...solution,
      data: solArr
    })
  }

  const validateSolution = () => {
    let solArr = [...solution.data];
    let errArr = [...solution.error];
    let errMsgArr = [...solution.errorMessage];
    let flag = true;
    solArr.map((item, i) => {
      if (!Yup.string().required().isValidSync(item)) {
        flag = false;
        errMsgArr[i] = "This Field is required";
        errArr[i] = true;
      } else
        if (!Yup.string().strict().trim().isValidSync(item)) {
          flag = false;
          errMsgArr[i] = "Trailing white spaces are not allowed";
          errArr[i] = true;
        } else
          if (!Yup.string().max(60).isValidSync(item)) {
            flag = false;
            errMsgArr[i] = "This field can only contain maximum 60 characters";
            errArr[i] = true;
          }

    })
    setSolution({
      ...solution,
      error: errArr,
      errorMessage: errMsgArr
    })
    return flag;
  }

  const AddSolution = () => {
    let solArr = [...solution.data];
    let errArr = [...solution.error];
    let errMsgArr = [...solution.errorMessage];
    let flag = validateSolution();

    if (flag) {
      solArr.push("");
      errArr.push(false);
      errMsgArr.push("");
      setSolution({
        ...solution,
        data: solArr,
        error: errArr,
        errorMessage: errMsgArr
      })
    }
  }

  const RemoveSolution = (i) => {
    let solArr = [...solution.data];
    let errArr = [...solution.error];
    let errMsgArr = [...solution.errorMessage];
    solArr.splice(i, 1);
    errArr.splice(i, 1);
    errMsgArr.splice(i, 1);
    setSolution({
      ...solution,
      data: solArr,
      error: errArr,
      errorMessage: errMsgArr
    })
  }

  const handleConfirmSolution = () => {
    let flag = validateSolution();

    if (flag) {
      setFieldValue('solution', [...solution.data]);

      setSolution({
        ...solution,
        show: false,
        data: []
      })
    }
  }


  /* Drag and drop hints */

  function onDragEndHints(result) {
    console.log(result);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newValue = reorder(
      values.hints,
      result.source.index,
      result.destination.index
    );
    // setFiles(reorderUploadFiles(files, result.source.index, result.destination.index))
    setValues({ ...values, hints: [...newValue] });
  }


  /* Drag and drop clues */




  // function reorderUploadFiles(obj, sourceIndex, destIndex) {
  //   // Check if both source and destination indices are valid
  //   if (obj.hasOwnProperty(sourceIndex) && obj.hasOwnProperty(destIndex)) {
  //     // Swap the items at the source and destination indices
  //     const temp = obj[sourceIndex];
  //     obj[sourceIndex] = obj[destIndex];
  //     obj[destIndex] = temp;
  //   }

  //   console.log("reorderUploadFiles", obj, sourceIndex, destIndex);
  //   return obj;
  // }


  function onDragEndClues(result) {
    console.log(result);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newValue = reorder(
      values.clues,
      result.source.index,
      result.destination.index
    );

    setValues({ ...values, clues: [...newValue] });
  }

  /* Trash Hints start */

  const [trash, setTrash] = useState(false);

  const handleRetrieveHint = (idx) => {
    let Elements = [...values.hints]
    // let Files = [...values.hintFiles];
    console.table(values.hints)
    console.table(idx)
    Elements[idx].deleteStatus = false;
    // Elements.splice(idx, 1);
    // Files.splice(fileIdx, 1);
    setValues({
      ...values,
      hints: Elements,
      // hintFiles: Files,
    })
  }

  /* Trash Hints end */
  function isFile(input) {
    if ('File' in window && input instanceof File)
      return true;
    else return false;
  }

  function isBlob(input) {
    if ('Blob' in window && input instanceof Blob)
      return true;
    else return false;
  }
  return (
    <>
      <Modal show={solution.show} style={{ padding: "15px" }} centered backdrop="static">
        <ModalBody>
          <ModalBody>
            <Grid container>
              <Grid item lg={12} md={12}>
                <Box display="flex" mb={2} justifyContent="space-between">
                  <Typography>Enter Possible Solutions</Typography>
                  <span
                    style={{
                      cursor: "pointer"
                    }}>
                    <Tooltip title="Add More Solution">
                      <AddCircleOutlinedIcon onClick={() => { AddSolution() }} fontSize='large' />
                    </Tooltip>
                  </span>
                </Box>
              </Grid>
              {solution.data.map((element, i) => (
                <Grid key={i} item lg={12} md={12}>
                  <Box display="flex" mb={2} justifyContent="space-between">
                    <TextField
                      variant="outlined"
                      required
                      name="solution"
                      placeholder='Enter Solution'
                      onChange={(e) => { handleChangeSolution(e, i) }}
                      value={element}
                      error={Boolean(solution.error[i] && solution.errorMessage[i])}
                      helperText={solution.error[i] && solution.errorMessage[i]}
                    />
                    {solution.data.length &&
                      <Tooltip title="Remove Solution">
                        <RemoveCircleIcon fontSize='large' onClick={() => { RemoveSolution(i) }} />
                      </Tooltip>
                    }
                  </Box>
                </Grid>
              ))}
            </Grid>
            <DialogActions>
              <Button onClick={() => { handleCloseSolution() }} className="btn btn-outline-secondary btn-primary">CANCEL</Button>
              <Button onClick={() => { handleConfirmSolution() }} className="btn btn-primary">SAVE</Button>
            </DialogActions>
          </ModalBody>
        </ModalBody>
      </Modal>
      <Modal show={del.show} style={{ padding: "15px" }} centered backdrop="static">
        <ModalBody>
          <ModalTitle as="h5">Confirm remove</ModalTitle>
          <ModalBody>
            <Typography variant='subtitle1'>Are You sure you want to remove this {del.type}?</Typography>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => { handleCancelDel() }} variant="outlined" className="btn btn-outline-secondary btn-primary">CANCEL</Button>
            <Button onClick={() => { handleConfirmDel() }} className="btn btn-primary">CONFIRM</Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
      <Modal size='lg' show={upload.show} style={{ padding: "15px" }} centered backdrop="static">
        <ModalBody>
          <h4 style={{ marginBottom: '20px', textTransform: 'capitalize' }}>{`${upload.upload}`}</h4>
          <Grid container spacing={3} mt={3}>
            <Grid item lg={6} md={12}>
              <InputLabel>Title*</InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                required
                name="title"
                placeholder='Enter Title'
                onChange={(e) => { handleChangeUpload(e) }}
                value={upload.title}
                error={uploadErr.titleErr}
                helperText={uploadErr.titleErr && uploadErr.title}
              />
            </Grid>
            {upload.upload === 'hint' && (
              <>
                <Grid item lg={6} md={12}>
                  <InputLabel>Points*</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    required
                    type="number"
                    name="points"
                    placeholder='Enter Points'
                    onChange={(e) => { handleChangeUpload(e) }}
                    value={upload.points}
                    error={uploadErr.pointsErr}
                    helperText={uploadErr.pointsErr && uploadErr.points}
                  />
                </Grid>
              </>
            )}
            <Grid item lg={6} xs={12}>
              <InputLabel>Type*</InputLabel>
              <FormControl error={uploadErr.typeErr}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={upload.type}
                  defaultValue={upload.type}
                  name="type"
                  onChange={(e) => { handleChangeUpload(e) }}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <Grid container>
                    <Grid item lg={6} xs={12}>
                      <FormControlLabel value="file" control={<Radio />} label="File" />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <FormControlLabel value="text" control={<Radio />} label="Text" />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
              <FormHelperText style={{ color: 'red' }}>{uploadErr.typeErr && uploadErr.type}</FormHelperText>
            </Grid>

            {upload.type === 'text' &&
              <Grid item xs={12} md={12}>
                <InputLabel>Text*</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  multiline
                  minRows={1}
                  maxRows={5}
                  name="text"
                  placeholder='Enter Text'
                  value={upload.text}
                  error={uploadErr.textErr}
                  helperText={uploadErr.textErr && uploadErr.text}
                  onChange={(e) => { handleChangeUpload(e) }} />

              </Grid>
            }
            {upload.type === 'file' &&
              <>
                <Grid item xs={12} md={6}>
                  <InputLabel>File*</InputLabel>
                  <input type="file" name="revealFiles" className="form-control" accept={allowed_ext.map(ext => '.' + ext).join(',')} onChange={(e) => { handleDropFiles(e, upload?.type) }} />
                  {uploadErr.fileErr && <small className="text-danger form-text">{uploadErr.fileErr && uploadErr.file}</small>}
                </Grid>
                <Grid item xs={12} md={6}>
                  {upload.fileType && (upload.fileType).split("/")[0] === "video" ?
                    <video
                      preload="auto"
                      controls
                      controlsList="nodownload"
                      style={{
                        top: 8,
                        borderRadius: 1,
                        // border:"2px solid #000",              
                        objectFit: 'contain',
                        // padding: '5px',
                        width: 'calc(100% - 2px)',
                        height: '200px'
                      }}
                    >
                      <source src={upload.filePreview}></source>
                    </video>
                    : upload.fileType && (upload.fileType).split("/")[0] === "image" ?
                      <>
                        <Box
                          component="img"
                          alt="file preview"
                          src={upload.file ? typeof upload.file == "string" ? upload.file : URL.createObjectURL(upload.file) : upload.filePreview}
                          sx={{
                            top: 8,
                            borderRadius: 1,
                            // border:"2px solid #000",              
                            objectFit: 'contain',
                            // padding: '5px',
                            width: 'calc(100% - 2px)',
                            height: '200px'
                          }}
                        />
                      </>
                      : upload.fileType && (upload.fileType).split("/")[0] === "audio" ?
                        <div id="hint-need-audio">
                          <AudioPlayerAdmin src={upload.filePreview} />
                        </div>
                        : <></>}
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabel>Description</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    required
                    multiline
                    minRows={1}
                    maxRows={5}
                    name="description"
                    placeholder='Enter Description'
                    value={upload.description}
                    // error={uploadErr.descriptionErr}
                    // helperText={uploadErr.descriptionErr && uploadErr.description}
                    onChange={(e) => { handleChangeUpload(e) }} />

                </Grid>
              </>
            }
          </Grid>
          <DialogActions>
            <MButton onClick={() => { handleCloseUpload() }} variant="outlined" className="btn btn-outline-secondary">Cancel</MButton>
            <MButton onClick={() => { handleConfirmUpload() }} className="btn btn-primary">Confirm</MButton>
          </DialogActions>
        </ModalBody>
      </Modal>
      <Modal show={trash} size="lg" style={{ padding: "15px" }} centered backdrop="static">
        <ModalBody>
          <ModalTitle as="h5">Hint Trash</ModalTitle>
          <ModalBody>
            <TableContainer component={Paper} style={{ margin: '20px 0', borderRadius: 0 }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Points</TableCell>
                    <TableCell>Hint</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ cursor: 'default' }}>
                  {values.hints.map((element, idx) => {
                    // console.log("######$$$ 1272 values.hints", values)
                    return (
                      element.deleteStatus === true ?
                        <TableRow key={idx}>
                          <TableCell>{element.title !== "" && element.title !== null && element.title !== undefined ? element.title : '-'}</TableCell>
                          <TableCell>{element.description !== "" && element.description !== null && element.description !== undefined ? element.description : '-'}</TableCell>
                          <TableCell>{element.points}</TableCell>
                          <TableCell>
                            {element.hintsType === 'text' && element.text}
                            {element.hintsType === 'file' &&
                              element.fileType && (element.fileType).split("/")[0] === "video" ?
                              <video
                                preload="auto"
                                controls
                                controlsList="nodownload"
                                style={{
                                  top: 8,
                                  borderRadius: 1,
                                  // border:"2px solid #000",              
                                  objectFit: 'contain',
                                  // padding: '5px',
                                  height: '75px'
                                }}
                              >
                                <source src={element.preview ? element.preview : element.url}></source>
                              </video>
                              : element.fileType && (element.fileType).split("/")[0] === "image" ?
                                <Box
                                  component="img"
                                  alt="file preview"
                                  src={element.preview ? element.preview : element.url}
                                  sx={{
                                    top: 8,
                                    borderRadius: 1,
                                    // border:"2px solid #000",              
                                    objectFit: 'contain',
                                    // padding: '5px',
                                    height: '75px'
                                  }}
                                />

                                : element.fileType && (element.fileType).split("/")[0] === "audio" ?
                                  <Grid key={idx} item lg={8} md={12} xs={12}>
                                    <div id="hint-need-audio">
                                      <AudioPlayerAdmin src={element.preview ? element.preview : element.url} />
                                    </div>
                                  </Grid>
                                  : <></>
                            }
                            {element.hintsType === 'file' &&
                              element.mediaType && (element.mediaType).split("/")[0] === "video" ?
                              <video
                                preload="auto"
                                controls
                                controlsList="nodownload"
                                style={{
                                  top: 8,
                                  borderRadius: 1,
                                  // border:"2px solid #000",              
                                  objectFit: 'contain',
                                  // padding: '5px',
                                  // width: 'calc(100% - 2px)',
                                  height: '75px'
                                }}
                              >
                                <source src={`${url}${element.url}`}></source>
                              </video>
                              : element.mediaType && (element.mediaType).split("/")[0] === "image" ?
                                <Box
                                  component="img"
                                  alt="file preview"
                                  src={`${url}${element.url}`}
                                  sx={{
                                    top: 8,
                                    borderRadius: 1,
                                    // border:"2px solid #000",              
                                    objectFit: 'contain',
                                    // padding: '5px',
                                    // width: 'calc(100% - 2px)',
                                    height: '75px'
                                  }}
                                />

                                : element.mediaType && (element.mediaType).split("/")[0] === "audio" ?
                                  <Grid key={idx} item lg={8} md={12} xs={12} style={{ maxWidth: "initial" }}>
                                    <div id="hint-need-audio">
                                      <AudioPlayerAdmin src={`${url}${element.url}`} />
                                    </div>
                                  </Grid>
                                  : <></>
                            }
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Recover">
                              <RestoreFromTrash className='dlt-icon' onClick={() => { handleRetrieveHint(idx) }} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                        : null
                    )
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => { setTrash(false) }} variant="outlined" className="btn btn-outline-secondary btn-primary">Close</Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <InputLabel>Name*</InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                required
                placeholder='Enter Name'
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                {...getFieldProps('name')} />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputLabel>Chapter Description</InputLabel>
              <TextField
                fullWidth
                placeholder='Enter Description'
                variant="outlined"
                value={values.description}
                required
                multiline
                minRows={3}
                maxRows={5}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                {...getFieldProps('description')} />
            </Grid>
            {(!hideSol || values.puzzleType == "text") && <Grid item xs={12} md={6}>
              <InputLabel>Possible Solution</InputLabel>
              {/* <TextField 
                fullWidth
                variant="outlined" 
                required
                placeholder='Enter Solution'
                value={values.solution}
                error={Boolean(touched.solution && errors.solution)}
                helperText={touched.solution && errors.solution}
                {...getFieldProps('solution')} />             */}

              <MButton
                variant='contained'
                color="primary"
                startIcon={<AddCircleOutlinedIcon />}
                onClick={() => { handleOpenSolution() }}
              >
                Edit Possible Solutions
              </MButton>
              <Stack flexDirection="row" display="flex" flexWrap="wrap">
                {values.solution[0] !== "" && values.solution.map((item, i) => (
                  <Chip key={i} label={item} style={{ margin: "5px" }} />
                ))}
              </Stack>
            </Grid>}
            {/* <Grid item xs={12} md={6}>
              <InputLabel>Reveal Answer Penalty</InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                type='number'
                required
                placeholder='Enter Reveal Answer Penalty'
                value={values.solutionPoints}
                error={Boolean(touched.solutionPoints && errors.solutionPoints)}
                helperText={touched.solutionPoints && errors.solutionPoints}
                {...getFieldProps('solutionPoints')} />
            </Grid> */}
            {(!hideSol || values.puzzleType == "text") && <Grid item xs={12} md={12}>
              <InputLabel>Solution Description</InputLabel>
              <TextField
                fullWidth
                placeholder='Enter Description'
                variant="outlined"
                value={values.solutionDescription}
                required
                multiline
                minRows={3}
                maxRows={5}
                error={Boolean(touched.solutionDescription && errors.solutionDescription)}
                helperText={touched.solutionDescription && errors.solutionDescription}
                {...getFieldProps('solutionDescription')} />
            </Grid>}
            <Grid item xs={12} lg={6}>
              <InputLabel id="demo-radio-buttons-group-label">Puzzle Type</InputLabel>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="file"
                  name="puzzleType"
                  style={{ display: 'flex', flexDirection: 'row' }}
                  {...getFieldProps('puzzleType')}
                >
                  <FormControlLabel value="file" control={<Radio />} label="File" />
                  <FormControlLabel value="text" control={<Radio />} label="Text" />
                </RadioGroup>
              </FormControl>

            </Grid>
            {values.puzzleType === 'file' &&
              <Grid item xs={12} md={6}>
                <InputLabel>Puzzle File</InputLabel>
                <input type="file" name="puzzle" className="form-control" accept={allowed_ext.map(ext => '.' + ext).join(',')} onChange={handleDropPuzzle} style={{ marginBottom: '4px' }} />
                {Boolean(touched.puzzle && errors.puzzle) && <small className="text-danger form-text">{touched.puzzle && errors.puzzle}</small>}
                <EditPuzzleDisplay displayPuzzle={displayPuzzle ? displayPuzzle : null} />
              </Grid>
            }
            {values.puzzleType === 'text' &&
              <Grid item xs={12} md={6}>
                <InputLabel>Puzzle Text</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  placeholder='Enter Puzzle Text'
                  value={values.puzzleText}
                  error={Boolean(touched.puzzleText && errors.puzzleText)}
                  helperText={touched.puzzleText && errors.puzzleText}
                  onChange={(e) => { handlePuzzleText(e) }} />

              </Grid>}
            <Grid item lg={12} xs={12}>
              <InputLabel>Hints/Clues</InputLabel>
              <Box spacing={2} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Grid item lg={12} xs={6}>
                  <MButton
                    variant='contained'
                    color="primary"
                    startIcon={<AddCircleOutlinedIcon />}
                    onClick={() => { handleOpenUpload('hint') }}
                  >Add Hints</MButton>
                  <MButton
                    className="ml-2"
                    variant='contained'
                    color="primary"
                    onClick={() => { setTrash(true) }}
                  >Trash Hints</MButton>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Reveal Answer Penalty</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type='number'
                    required
                    placeholder='Enter Reveal Answer Penalty'
                    value={values.solutionPoints}
                    error={Boolean(touched.solutionPoints && errors.solutionPoints)}
                    helperText={touched.solutionPoints && errors.solutionPoints}
                    {...getFieldProps('solutionPoints')} />
                </Grid>
              </Box>
              <FormHelperText style={{ color: 'red' }}>{touched.hints && errors.hints}</FormHelperText>
              <DragDropContext onDragEnd={onDragEndHints}>
                <Droppable direction='vertical' droppableId="list">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {values?.hints && Array.isArray(values?.hints) && values?.hints?.length !== 0 ?
                        <TableContainer component={Paper} style={{ margin: '20px 0', borderRadius: 0 }}>
                          <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Reorder</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Points</TableCell>
                                <TableCell>Hint</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody style={{ cursor: 'default' }}>

                              {values.hints.map((element, idx) => {
                                // console.log("###############  1555 values.hints", values.hints)
                                return (element?.deleteStatus ? null
                                  : <Draggable key={idx} draggableId={idx.toString()} index={idx}>
                                    {(provided) => (
                                      <TableRow
                                        key={idx}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >

                                        <TableCell>
                                          <OpenWithIcon style={{ cursor: 'grab' }} />
                                        </TableCell>
                                        <TableCell>{element.title}</TableCell>
                                        <TableCell>{element.description}</TableCell>
                                        <TableCell>{element.points}</TableCell>
                                        <TableCell>
                                          {element.hintsType === 'text' && element.text}
                                          {element.hintsType === 'file' &&
                                            element.fileType && (element.fileType).split("/")[0] === "video" ?
                                            <video
                                              preload="auto"
                                              controls
                                              controlsList="nodownload"
                                              style={{
                                                top: 8,
                                                borderRadius: 1,
                                                // border:"2px solid #000",              
                                                objectFit: 'contain',
                                                // padding: '5px',
                                                height: '75px'
                                              }}
                                            >
                                              <source src={element.preview ? element.preview : element.url}></source>
                                            </video>
                                            : element.fileType && (element.fileType).split("/")[0] === "image" ?
                                              <Box
                                                component="img"
                                                alt="file preview"
                                                src={element.preview ? element.preview : element.url}
                                                sx={{
                                                  top: 8,
                                                  borderRadius: 1,
                                                  // border:"2px solid #000",              
                                                  objectFit: 'contain',
                                                  // padding: '5px',
                                                  height: '75px'
                                                }}
                                              />

                                              : element.fileType && (element.fileType).split("/")[0] === "audio" ?
                                                <Grid key={idx} item lg={8} md={12} xs={12}>
                                                  <div id="hint-need-audio">
                                                    <AudioPlayerAdmin src={element.preview ? element.preview : element.url} />
                                                  </div>
                                                </Grid>
                                                : <></>
                                          }
                                          {element.hintsType === 'file' &&
                                            element.mediaType && (element.mediaType).split("/")[0] === "video" ?
                                            <video
                                              preload="auto"
                                              controls
                                              controlsList="nodownload"
                                              style={{
                                                top: 8,
                                                borderRadius: 1,
                                                // border:"2px solid #000",              
                                                objectFit: 'contain',
                                                // padding: '5px',
                                                // width: 'calc(100% - 2px)',
                                                height: '75px'
                                              }}
                                            >
                                              <source src={`${url}${element.url}`}></source>
                                            </video>
                                            : element.mediaType && (element.mediaType).split("/")[0] === "image" ?
                                              <Box
                                                component="img"
                                                alt="file preview"
                                                src={`${url}${element.url}`}
                                                sx={{
                                                  top: 8,
                                                  borderRadius: 1,
                                                  // border:"2px solid #000",              
                                                  objectFit: 'contain',
                                                  // padding: '5px',
                                                  // width: 'calc(100% - 2px)',
                                                  height: '75px'
                                                }}
                                              />

                                              : element.mediaType && (element.mediaType).split("/")[0] === "audio" ?
                                                <Grid key={idx} item lg={8} md={12} xs={12} style={{ maxWidth: "initial" }}>
                                                  <div id="hint-need-audio">
                                                    <AudioPlayerAdmin src={`${url}${element.url}`} />
                                                  </div>
                                                </Grid>
                                                : <></>
                                          }
                                        </TableCell>
                                        <TableCell>
                                          <Tooltip title="Edit Hint">
                                            <EditIcon onClick={() => { handleOpenUpload('hint', idx, element.fileIndex, element) }} style={{ cursor: "pointer" }} />
                                          </Tooltip>
                                          <Tooltip title="Delete Hint">
                                            <DeleteIcon className='dlt-icon' onClick={() => { handleOpenDel('hint', idx, element.fileIndex) }} style={{ cursor: 'pointer' }} />
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </Draggable>

                                )
                              })}

                            </TableBody>
                          </Table>
                        </TableContainer> : ''}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <MButton
                variant='contained'
                color="primary"
                startIcon={<AddCircleOutlinedIcon />}
                onClick={() => { handleOpenUpload('clue') }}
              >Add Clues</MButton>
              <FormHelperText style={{ color: 'red' }}>{touched.clues && errors.clues}</FormHelperText>
              <DragDropContext onDragEnd={onDragEndClues}>
                <Droppable direction='vertical' droppableId="list">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {values.clues && Array.isArray(values?.clues) && values?.clues?.length !== 0 ?
                        <TableContainer component={Paper} style={{ margin: '20px 0', borderRadius: 0 }}>
                          <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Reorder</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Clues</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody style={{ cursor: 'default' }}>
                              {values.clues.map((element, idx) => {
                                // console.log("####### 1702 values.CLUES", values)
                                return (<Draggable key={idx} draggableId={idx.toString()} index={idx}>
                                  {(provided) => (
                                    <TableRow
                                      key={idx}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <TableCell>
                                        <OpenWithIcon style={{ cursor: 'grab' }} />
                                      </TableCell>
                                      <TableCell>{element.title !== "" && element.title !== null && element.title !== undefined ? element.title : '-'}</TableCell>
                                      <TableCell>{element.description !== "" && element.description !== null && element.description !== undefined ? element.description : '-'}</TableCell>
                                      <TableCell>
                                        {element.cluesType === 'text' && element.text}
                                        {element.cluesType === 'file' &&
                                          element.fileType && (element.fileType).split("/")[0] === "video" ?
                                          <video
                                            preload="auto"
                                            controls
                                            controlsList="nodownload"
                                            style={{
                                              top: 8,
                                              borderRadius: 1,
                                              // border:"2px solid #000",              
                                              objectFit: 'contain',
                                              // padding: '5px',
                                              height: '75px'
                                            }}
                                          >
                                            <source src={element.preview ? element.preview : element.url}></source>
                                          </video>
                                          : element.fileType && (element.fileType).split("/")[0] === "image" ?
                                            <Box
                                              component="img"
                                              alt="file preview"
                                              src={element.preview ? element.preview : element.url}
                                              sx={{
                                                top: 8,
                                                borderRadius: 1,
                                                // border:"2px solid #000",              
                                                objectFit: 'contain',
                                                // padding: '5px',
                                                height: '75px'
                                              }}
                                            />

                                            : element.fileType && (element.fileType).split("/")[0] === "audio" ?
                                              <Grid key={idx} item lg={8} md={12} xs={12} >
                                                <div id="hint-need-audio">
                                                  <AudioPlayerAdmin src={element.preview ? element.preview : element.url} />
                                                </div>
                                              </Grid>
                                              : <></>
                                        }
                                        {element.cluesType === 'file' &&
                                          element.mediaType && (element.mediaType).split("/")[0] === "video" ?
                                          <video
                                            preload="auto"
                                            controls
                                            controlsList="nodownload"
                                            style={{
                                              top: 8,
                                              borderRadius: 1,
                                              // border:"2px solid #000",              
                                              objectFit: 'contain',
                                              // padding: '5px',
                                              // width: 'calc(100% - 2px)',
                                              height: '75px'
                                            }}
                                          >
                                            <source src={`${url}${element.url}`}></source>
                                          </video>
                                          : element.mediaType && (element.mediaType).split("/")[0] === "image" ?
                                            <Box
                                              component="img"
                                              alt="file preview"
                                              src={`${url}${element.url}`}
                                              sx={{
                                                top: 8,
                                                borderRadius: 1,
                                                // border:"2px solid #000",              
                                                objectFit: 'contain',
                                                // padding: '5px',
                                                // width: 'calc(100% - 2px)',
                                                height: '75px'
                                              }}
                                            />

                                            : element.mediaType && (element.mediaType).split("/")[0] === "audio" ?
                                              <Grid key={idx} item lg={8} md={12} xs={12} style={{ maxWidth: "initial" }}>
                                                <div id="hint-need-audio">
                                                  <AudioPlayerAdmin src={`${url}${element.url}`} />
                                                </div>
                                              </Grid>
                                              : <></>
                                        }
                                      </TableCell>
                                      <TableCell>
                                        <Tooltip title="Edit Hint">
                                          <EditIcon onClick={() => { handleOpenUpload('clue', idx, element.fileIndex, element) }} style={{ cursor: "pointer" }} />
                                        </Tooltip>
                                        <Tooltip title="Delete Hint">
                                          <DeleteIcon className='dlt-icon' onClick={() => { handleOpenDel('clue', idx, element.fileIndex) }} style={{ cursor: 'pointer' }} />
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Draggable>)
                              })}

                            </TableBody>
                          </Table>
                        </TableContainer> : ''}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

            </Grid>
          </Grid>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
            <Button type="reset" onClick={() => { onClose() }} style={{ margin: '0px' }} className="btn btn-outline-secondary mr-3">
              BACK
            </Button>
            <Button type="submit" className="btn btn-primary">
              SAVE
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default EditPuzzle;