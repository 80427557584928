import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOnePod } from '../Services/PodServices';
import { styled } from '@material-ui/styles';
import moment from 'moment';
import {
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell
} from '@material-ui/core';
// import Table from 'react-bootstrap/Table';

const ButtonStyle = styled('div')(() => ({
  backgroundColor: "#1D1C1C",
  color: "#FFFB00",
  height: "25px",
  borderRadius: '5px',
  fontSize: 12,
  fontWeight: 700,
  padding: "2px 3px",
  textAlign: "center",
  // border:"2px solid #1D1C1C",
}));



const PodOneView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pod] = useState(location.state);
  const [podDetails, setPodDetails] = useState({
    name: '',
    email: '',
    totalTeams: '',
    game: '',
    minutes: '',
    startTime: '',
    endTime: '',
    gameLink: [],
    teamNames: [],
    teamLabels: []
  });
  useEffect(() => {
    getPodData();

  }, []);

  function convertUTCToLocalDate(date) {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return date
  }

  const getPodData = async () => {
    const data = await getOnePod({ _id: pod });
    let podData = data?.data?.data;
    const gameLinkList = podData.gameLink.map((data) => {
      return {
        ...data,
        btnText: 'Copy Link',
        tooltipText: 'Click to Copy Link'
      }
    })
    setPodDetails({
      ...podDetails,
      ['name']: podData.name,
      ['minutes']: podData.minutes,
      ['totalTeams']: podData.totalTeams,
      ['game']: podData.gameId.internalTitle,
      ['gameLink']: gameLinkList,
      ['teamNames']: podData.teamNames,
      ['date']: convertUTCToLocalDate(podData.date),
      ['time']: podData.time,
      ['email']: podData.email,
    });
  };


  const handleBack = () => {
    navigate('/admin/pod');
  };

  const resetButtonText = (idx) => {
    const interval = setInterval(updateButton, 1500);
    function updateButton() {
      let gameLinks = [...podDetails.gameLink];
      gameLinks[idx].btnText = 'Copy Link';
      gameLinks[idx].tooltipText = 'Click to Copy Link';

      setPodDetails({
        ...podDetails,
        gameLink: gameLinks
      });

      clearInterval(interval);
    };
    // clearInterval(interval);
  }


  const handleCopyLink = (value, idx) => {
    let gameLinks = [...podDetails.gameLink];
    gameLinks[idx].btnText = 'Copied';
    gameLinks[idx].tooltipText = 'Click again to Copy Link';
    navigator.clipboard.writeText(value.link)
    setPodDetails({
      ...podDetails,
      gameLink: gameLinks
    });

    resetButtonText(idx);
  }

  return (
    <div>
      <Card>
        <Card.Header>
          <Row>
            <Col md={11}>
              {' '}
              <Card.Title as="h5">Pod Detail</Card.Title>{' '}
            </Col>
            <Col md={1}>
              <Button className="btn btn-primary btn-sm" onClick={() => handleBack()}>
                BACK
              </Button>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col lg={3} md={3} xs={12}>
              <h6 className='fw-bold'>Pod Name</h6>
            </Col>
            <Col lg={9} md={9} xs={12}>
              <h6 className="">{podDetails?.name}</h6>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={3} md={3} xs={12}>
              <h6 className='fw-bold'>Minutes</h6>
            </Col>
            <Col lg={9} md={9} xs={12}>
              <h6 className="">{podDetails?.minutes}</h6>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={3} md={3} xs={12}>
              <h6 className='fw-bold'> Total Teams</h6>
            </Col>
            <Col lg={9} md={9} xs={12}>
              <h6 className="">{podDetails?.totalTeams}</h6>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg={3} md={3} xs={12}>
              <h6 className='fw-bold'>Game</h6>
            </Col>
            <Col lg={9} md={9} xs={12}>
              <h6 className="">{podDetails?.game}</h6>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg={3} md={3} xs={12}>
              <h6 className='fw-bold'>Start Date</h6>
            </Col>
            <Col lg={9} md={9} xs={12}>
              <h6>{moment(podDetails.date).format('MM/D/YY')}</h6>
            </Col>
          </Row>



          <Row className="mt-3">
            <Col lg={3} md={3} xs={12}>
              <h6 className='fw-bold'>Start Time</h6>
            </Col>
            <Col lg={9} md={9} xs={12}>
              <h6>{`${podDetails?.time?.hours}:${podDetails?.time?.minutes} ${podDetails?.time?.format}`}</h6>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg={3} md={3} xs={12}>
              <h6 className='fw-bold'>Email</h6>
            </Col>
            <Col lg={9} md={9} xs={12}>
              <h6>{podDetails.email}</h6>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg={3} md={3} xs={12}>
              <h6 className='fw-bold'>Game Link</h6>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <TableContainer component={Paper} style={{ borderRadius: 0, padding: 0 }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Team Name </TableCell>
                      <TableCell style={{ textAlign: "center" }}>Link</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {podDetails.gameLink.map((val, i) => (
                      <TableRow key={i}>
                        <TableCell>{val.linkLabelName ? val.linkLabelName : i + 1}</TableCell>
                        <TableCell style={{ wordBreak: "break-all" }}>{val.link}</TableCell>
                        <TableCell><Tooltip title={val.tooltipText}>
                          <button className="activeBttn" onClick={() => { handleCopyLink(val, i) }} style={{ cursor: 'pointer', border: 'none', backgroundColor: 'white' }}>
                            {val.btnText}
                          </button>
                        </Tooltip></TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row>
          {podDetails.teamNames.length > 0 &&
            <Row className="mt-3">
              <Col lg={3} md={3} xs={12}>
                <h6 className='fw-bold'>Team Members Joined</h6>
              </Col>
              <Col lg={12} md={12} xs={12}>
                <TableContainer component={Paper} style={{ borderRadius: 0, padding: 0 }}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Team Name </TableCell>
                        <TableCell>Members Joined</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {podDetails.teamNames.map((val, i) => (
                        <TableRow key={i}>
                          <TableCell>{val.teamName}</TableCell>
                          <TableCell style={{ wordBreak: "break-all" }}>{val.teamMembers.map((member, idx) => (idx === val.teamMembers.length - 1 ? `${member.name}` : `${member.name}, `))}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
            </Row>}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PodOneView;