import React, { useContext, useEffect, useState } from "react";
import generateIcon from "../../assets/images/generate.svg";
import { Box, Typography, Card, DialogContent } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import logo from "../../assets/images/user/logo.png";
import "../../assets/css/Design.css";
import { toast } from "react-toastify";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import {
  addTeamMember,
  addTeamName,
  checkValidUrl,
  getPodName,
  getTeamName,
  isPlayed
} from "./Services/Service";

import { teamDataSuccess, cleanAllData } from "../../Actions/ActionCreators";
import { useDispatch } from "react-redux";
import { SocketContext } from "../../contexts/socketConnection";
import { capitalize } from "lodash";
import { Modal } from "react-bootstrap";
import groupIcon from "../../assets/images/groupmember.svg";
import { Base_URL } from "./Config/Config";

const CardStyle = styled(Card)(() => ({
  borderRadius: "10px",
  backgroundColor: "#1D1C1C",
  color: "#FFF",
  padding: "10%",
  textAlign: "center",
}));

const ButtonStyle = styled("button")(() => ({
  backgroundColor: "#1D1C1C",
  width: "auto",
  color: "#FFF",
  textAlign: "center",
  border: "1px solid #FFF",
  fontSize: "20px",
  textTransform: "uppercase",
  fontFamily: "PFDinMonoMedium",
  // paddingRight: "30px",
  margin: "10px auto auto auto",
  display: "flex",
  alignItems: "center",
  background: "transparent",
  padding: "3px 10px",
}));

const InputStyle = styled("input")(() => ({
  width: "100%",
  backgroundColor: "inherit",
  border: "none",
  borderBottom: "2px #FFF solid",
  color: "#FFF",
}));

const InputLabelStyle = styled("label")(() => ({
  width: "100%",
  marginTop: "5px",
  color: "#7F7F7F",
  textAlign: "start",
  letterSpacing: "0.26px",
}));

const Welcome = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, gameId, podId } = useParams();
  // const socketRef = useRef();
  const socket = useContext(SocketContext);
  const [showTeam, setShowTeam] = useState({
    show: false,
    team: "testing",
  });
  const [userExists, setUserExists] = useState({
    show: false,
    username: "",
    teamData: {}
  });
  const [val, setVal] = useState({
    teamname: "",
    pod: "",
    game: "",
    disableTeam: false,
    memberCount: 0,
    teamMembers: [],
  });
  const [username, setUsername] = useState("");
  const [teamName, setTeamName] = useState("");
  const [step, setStep] = useState(0);
  console.log("teamName", teamName)
  const nameReg = /^[^,]+$/;

  const handleContinuePlay = () => {
    const teamData = userExists.teamData;
    if (teamData.teamDetails.teamName) {
      dispatch(teamDataSuccess(teamData));
      socket.emit("joinRoom", {
        teamMemberName: teamData.currentUser,
        roomName: teamData.teamDetails.teamName,
        teamId: teamData.teamDetails._id,
        podId: podId
      });

      navigate(`/user/${token}/${gameId}/${podId}/game`, {
        replace: true,
      });
      toast.success(`Welcome back ${teamData.currentUser}!!`);
    } else {
      setStep(1);
    }
    setUserExists({
      ...userExists,
      show: false,
      username: "",
      teamData: {}
    })
  }

  const handleDifferentUser = () => {
    setStep(0);
    setUsername("");
    setUserExists({
      ...userExists,
      show: false,
      username: "",
      teamData: {}
    })
  }



  useEffect(() => {
    playedGame()
  }, [])

  const playedGame = async () => {
    const sendData = {
      gameId: gameId,
      podId: podId,
      token: token,
    };
    try {
      console.log(sendData)
      const { data } = await isPlayed(sendData);
      if (data?.data?.played) {
        console.log("played well ")
        navigate(`/user/${token}/${gameId}/${podId}/status`, {
          replace: true,
        });
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleContinue = async () => {
    const memberLogin = localStorage.setItem("memberLogin", "login");
    console.log("localItem", memberLogin);
    //if member login  and meberName === valid_user
    const valid_user = username.trim();
    console.log("first");
    console.log(valid_user);
    const memberName = localStorage.setItem("memberName", valid_user);

    if (username.trim().length < 25) {
      if (
        valid_user !== "" &&
        valid_user !== null &&
        valid_user !== undefined
      ) {
        if (nameReg.test(valid_user)) {
          const sendData = {
            teamMemberName: valid_user,
            gameId: gameId,
            podId: podId,
            token: token,
            gameLink: `${window.location.protocol}//${window.location.host}/user/${token}/${gameId}/${podId}`,
          };
          try {
            const resp = await addTeamMember(sendData);

            if (resp.status === 200) {
              setStep(1);
              if (
                val.teamname !== "" &&
                val.teamname !== undefined &&
                val.teamname !== null
              ) {
                setShowTeam({
                  ...showTeam,
                  show: true,
                });
              }
              socket.emit("newActivity", { teamMemberName: username });
            }
          } catch (err) {
            if (
              err.response.data.success === false &&
              err.response.data.userExists === true
            ) {
              setUserExists({
                ...userExists,
                show: true,
                username: err.response.data.data.currentUser,
                teamData: { ...err.response.data.data }
              })
              return;
              // const teamData = err.response.data.data;
              // dispatch(teamDataSuccess(teamData));
              // socket.emit("joinRoom", {
              //   teamMemberName: teamData.currentUser,
              //   roomName: teamData.teamDetails.teamName,
              //   teamId: teamData.teamDetails._id,
              // });

              // navigate(`/user/${token}/${gameId}/${podId}/game`, {
              //   replace: true,
              // });
              // toast.success(`Welcome back ${teamData.currentUser}!!`);
            } else {
              toast.error(err.response.data.message);
            }

            setStep(0);
          }
        } else {
          toast.error("Please Enter Valid Username");
        }
      } else {
        toast.error("Please Enter Valid Username");
      }
    } else {
      toast.error("User name must be less than 25 characters.");
    }
  };

  const handleChangeTeam = (e) => {
    const inputVal = e.target.value;
    console.log(inputVal);
    var re = /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/;
    let valid_input = re.test(inputVal);
    if (inputVal.length < 25) {
      if (valid_input) {
        setTeamName(inputVal);
      } else if (!valid_input && inputVal[inputVal.length - 2] !== " ") {
        setTeamName(inputVal);
      } else {
        setTeamName(inputVal);
      }
    } else {
      toast.error("must be less than 25 characters.");
    }
  };

  const handleChangeUser = (e) => {
    const inputVal = e.target.value;
    var re = /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/;
    let valid_input = re.test(inputVal);
    if (inputVal.length < 25) {
      if (valid_input) {
        setUsername(inputVal);
      } else if (!valid_input && inputVal[inputVal.length - 2] !== " ") {
        setUsername(inputVal);
      } else {
        setUsername(inputVal);
      }
    } else {
      toast.error("must be less than 25 characters.");
    }
  };

  const handleSave = async () => {
    if (teamName !== "" && teamName !== null && teamName !== undefined) {
      const sendData = {
        currentUser: localStorage.getItem("memberName"),
        teamName: teamName,
        gameId: gameId,
        podId: podId,
        token: token,
        gameLink: `${window.location.protocol}//${window.location.host}/user/${token}/${gameId}/${podId}`,
      };
      try {
        const response = await addTeamName(sendData);
        // console.log(response);
        const recvData = response.data.data;

        if (response.status === 200) {
          dispatch(teamDataSuccess(recvData));
          // socket.emit('showTeamName', {teamName:teamName})
          console.log("8888888888888", recvData);
          socket.emit("joinRoom", {
            teamMemberName: localStorage.getItem("memberName"),
            roomName: recvData.teamDetails.teamName,
            teamId: recvData.teamDetails._id,
            podId: podId
          });
          console.log("999999999999999");
          navigate(`/user/${token}/${gameId}/${podId}/instruction`, {
            replace: true,
          });
        }
      } catch (errrr) {
        toast.error(errrr.response.data.message);
        console.log(errrr, "errrr");
        setVal({
          ...val,
          username: "",
        });
        setStep(0);
      }
    } else {
      toast.error("Please Enter Team Name!!");
    }
  };

  // const getTeam = async () =>{
  //   let param = {
  //     gameId: gameId,
  //     podId: podId,
  //     token: token,
  //   }
  //   let resp = await getTeamName(param);
  //   const data = resp.data.data;
  //   if(data && resp.data.success){
  //     setVal({
  //       ...val,
  //       ['teamname']:data.teamName,
  //       ['memberCount']:data.totalTeamMembers,
  //       ['disableTeam']:true,
  //       ['teamMembers']:data.teamMembers
  //     })
  //   }

  // }

  const checkValidLink = async () => {
    try {
      let resp = await checkValidUrl({
        gameLink: `${window.location.protocol}//${window.location.host}/user/${token}/${gameId}/${podId}`,
      });
      if (resp.status === 200) {
        console.log("valid");
      }
    } catch (err) {
      if (err.response.status === 419) {
        navigate("/404", { state: { pod_id: podId, game_id: gameId } });
      }
      // toast.error(err.response.data.message)
    }
  };

  const [podName, setPodName] = useState();
  const [labelData, setLabelData] = useState();

  const getPod = async () => {
    try {
      const res = await getPodName({ _id: podId, gameId: gameId });
      console.log("first", res?.data?.labelData);
      setPodName(res?.data?.data?.name);
      setLabelData(res?.data?.labelData);
    } catch (err) {
      navigate("/404", { state: { pod_id: podId, game_id: gameId } });
    }
  };

  useEffect(() => {
    getPod();
    localStorage.clear()
  }, []);

  useEffect(() => {
    dispatch(cleanAllData());

    socket.on("connect", () => {
      checkValidLink();
    });

    socket.on("showTeamName", () => {
      if (step == 1) {
        setShowTeam({
          ...showTeam,
          show: true,
        });
        // console.log("inside if");
      }
      // setShowTeam({
      //   ...showTeam,
      //   show:true
      // })
      // console.log("outside if", step);
    });

    socket.on("getTeamName", (data) => {
      // console.log(data)
      if (data.teamName) {
        setVal({
          ...val,
          ["teamname"]: data.teamName,
          ["memberCount"]: data.totalTeamMembers,
          ["disableTeam"]: true,
          ["teamMembers"]: data.teamMembers,
        });
        setTeamName(data.teamName);
      } else {
        if (data.totalTeamMembers > 0) {
          setVal({
            ...val,
            ["memberCount"]: data.totalTeamMembers,
            ["teamMembers"]: data.teamMembers,
          });
        }
      }
    });

    const interval = setInterval(() => {
      // getTeam()
      socket.emit("getTeamName", {
        gameId: gameId,
        podId: podId,
        token: token,
      });
    }, 1000);

    return () => {
      clearInterval(interval);
      setVal({
        username: "",
        teamname: "",
        pod: "",
        game: "",
        step: 0,
        disableTeam: false,
        memberCount: 0,
        teamMembers: [],
      });
    };
  }, [step]);

  const handleCloseTeam = () => {
    handleSave();
    setShowTeam({
      ...showTeam,
      team: "",
      show: false,
    });
  };


  var adjective = [
    "Fearless",
    "Hilarious",
    "Brilliant",
    "Aptly Named",
    "Brave",
    "Gorgeous",
    "Average",
    "One-Of-A-Kind",
    "Not Too Shabby",
    "Unbeatable",
    "Unrelenting",
    "Noble",
    "Scholarly",
    "Sophisticated",
    "Inspirational",
    "Stupendous",
    "Dastardly",
    "Buff",
    "Funky",
    "Elite",
    "Mighty",
    "Marvelous",
    "Phenomenal",
    "Crafty",
    "Sneaky",
    "Cool",
    "Righteous",
    "Awesome",
    "Suave",
    "Quirky",
    "Quizzical",
    "Old School",
    "Trendy",
    "Innovating",
    "Sweet And Sour",
    "Skilled",
    "Nurturing",
    "Fancy",
    "Epic",
    "Charismatic",
    "Rowdy",
    "Legendary",
    "Natural",
    "Royal",
    "Bodacious",
    "Radical",
    "Wiley",
    "Learned",
    "Spunky",
    "Signature",
    "Fresh",
    "Extraordinary",
    "Spicy",
    "Gutsy",
    "Radiant",
    "Bright",
    "Elegant",
    "Tubular",
    "Super",
    "Heartfelt",
  ];
  var object = [
    "Warriors",
    "Penguins",
    "Bananas",
    "Overachievers",
    "Ducks",
    "Knuckleheads",
    "Bozos",
    "Geniuses",
    "Diamonds In The Rough",
    "Escape Masters",
    "Titans",
    "Rapscallions",
    "Heroes",
    "Villains",
    "Enforcers",
    "Pirates",
    "Dragons",
    "Money Makers",
    "Dogs",
    "Avengers",
    "Party Rockers",
    "Cobras",
    "Icons",
    "Role Models",
    "Hustlers",
    "Cats",
    "Foxes",
    "Dancers",
    "Alligators",
    "Calculators",
    "Go Getters",
    "Experts",
    "Agents",
    "Angels",
    "Aces",
    "Captains",
    "Heartthrobs",
    "Peaches",
    "Wizards",
    "Tsunamis",
    "Eagles",
    "Cows",
    "Masterminds",
    "Favorites",
    "Prodigies",
    "Lions",
    "Wildcats",
    "Fighters",
    "Phenomenons",
    "Best In Class",
    "Crushers",
    "Ramblers",
    "Blabbermouths",
    "Generals",
    "Commanders",
    "Underdogs",
    "Head Bangers",
    "Puppeteers",
    "Friends",
    "Enthusiasts",
  ];

  const randomNameGenerator = (num) => {
    let res =
      adjective[Math.floor(Math.random() * adjective.length)] +
      " " +
      object[Math.floor(Math.random() * object.length)];

    let r = (Math.random() + 1).toString(36).substring(2);
    console.log("random", r);
    return res;
  };



  return (
    <>
      <div className="form-bg">
        <div className="form-bg-inner">
          <div className="text-center">
            <img className="logo-screen" width="320px" src={logo} />
          </div>

          <div className="form-box">
            {step === 0 ? (
              <>
                <div className="form-head">
                  <h1>{labelData?.welcome} {podName}! </h1>
                </div>
                <form onSubmit={(e) => { e.preventDefault() }} autocomplete="off" novalidate>
                  <InputStyle
                    placeholder={labelData?.enterYourNamePlaceholderText}
                    id="new-password"
                    name="new-password"
                    value={username}
                    onChange={(e) => { handleChangeUser(e) }}
                    // autoComplete="off"
                    autocomplete="new-password"
                    list="autocompleteOff"
                    title="Member Username"
                  />
                  <Box mt={4} display="flex" justifyContent="center">
                    <button
                      type="submit"
                      className="styledBtn px-btn"
                      onClick={() => { handleContinue() }}
                    >
                      <span>
                        {labelData?.continueButtonOnWelcome}
                      </span>
                      {/* <span>
                      <svg id="arrow.e0e8f1ef" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path id="Path_96" data-name="Path 96" d="M0,0H24V24H0Z" fill="none"/>
                        <path id="Path_97" data-name="Path 97" d="M16,9.414,7.4,18.021,5.983,16.607,14.589,8H7V6H18V17H16Z"/>
                      </svg>
                    </span> */}
                    </button>
                  </Box>
                </form>
              </>
            ) : (
              <>
                <div className="form-head text-center">
                  <h1 style={{ marginBottom: "0px" }}>
                    {labelData?.teamNameLabel}
                  </h1>
                  <span className="yallow-content yallow-color" style={{ paddingTop: "0px" }}   >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img src={groupIcon} style={{ marginRight: "10px", fontSize: "medium" }} />
                      <h6
                        style={{
                          color: "rgb(187 184 8)",
                          fontFamily: "proxima_novalight",
                          textAlign: "center",
                          paddingTop: "8px",
                          fontSize: "18px",
                        }}
                      >{`${labelData?.teammatesJoined}: ${val.memberCount}`}</h6>
                    </div>
                  </span>
                  <p>{labelData?.teamNameDesc}</p>
                </div>
                <Box display="flex" flexDirection={"row"}>
                  <div className="generator-box">
                    <InputStyle
                      placeholder={labelData?.teamNamePlaceholderText}
                      name="teamname"
                      value={teamName}
                      onChange={handleChangeTeam}
                      disabled={val.disableTeam}
                    />
                    <span className="genrator-icon">
                      <img
                        style={{ width: "21px" }}
                        src={generateIcon}
                        onClick={() => {
                          setTeamName(randomNameGenerator(8));
                        }}
                      />
                    </span>
                  </div>
                </Box>
                {/* <InputLabelStyle>
                  {labelData?.teamNameLabel}
                </InputLabelStyle> */}
                <Box mt={4}>
                  <button
                    className="styledBtn px-btn"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    <span>
                      {labelData?.saveButtonLabel}
                    </span>
                    {/* <span>
                      <svg id="arrow.e0e8f1ef" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path id="Path_96" data-name="Path 96" d="M0,0H24V24H0Z" fill="none"/>
                      <path id="Path_97" data-name="Path 97" d="M16,9.414,7.4,18.021,5.983,16.607,14.589,8H7V6H18V17H16Z"/>
                      </svg>
                    </span> */}
                  </button>
                </Box>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="teammatesJoining">
          <h1>
            {val.teamMembers.length > 0
              ? `${labelData?.teammatesJoining}`
              : `${labelData?.teammatesJoining}`}{" "}
            <span>
              {val.teamMembers.length > 0 &&
                `${val.teamMembers.map((member, idx) => member.name)}`
                  .split(",")
                  .join(", ")}
            </span>
          </h1>
        </div>
      </div>

      <Modal
        className="common-modal"
        show={showTeam.show}
        centered
        style={{ borderRadius: "0px !important" }}
      >
        <div className="modal-body text-center">
          <h4 className="modal-title">{labelData?.yourTeamNamedNow ? labelData?.yourTeamNamedNow : "Your team is named"}</h4>
          {/* <h5 className="sub-content"></h5> */}
          <h5 className="sub-content team-name">{teamName}</h5>
          <button
            type="button"
            className="styledBtn px-btn"
            style={{ marginTop: "28px" }}
            onClick={() => {
              handleCloseTeam();
            }}
          >
            <span>
              {labelData?.yourTeamNamePopupContinueButton}
            </span>
            {/* <span>
                  <svg id="arrow.e0e8f1ef" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path id="Path_96" data-name="Path 96" d="M0,0H24V24H0Z" fill="none"/>
                    <path id="Path_97" data-name="Path 97" d="M16,9.414,7.4,18.021,5.983,16.607,14.589,8H7V6H18V17H16Z"/>
                  </svg>
                </span> */}
          </button>
        </div>
      </Modal>
      <Modal
        className="common-modal"
        show={userExists.show}
        centered
        style={{ borderRadius: "0px !important" }}
      >
        <div className="modal-body text-center">
          {/* <h4 className="modal-title">{`There is already someone named ${userExists.username} on this team.`}</h4> */}
          <h5 className="sub-content">{`There is already someone named ${userExists.username} on this team.`}</h5>
          {/* <h5 className="sub-content team-name">{teamName}</h5> */}
          <button
            type="button"
            className="styledBtn px-btn"
            style={{ marginTop: "28px" }}
            onClick={() => {
              handleDifferentUser();
            }}
          >
            <span>
              Choose A Different Name
            </span>
          </button>
          <button
            type="button"
            className="styledBtn px-btn"
            style={{ marginTop: "28px" }}
            onClick={() => {
              handleContinuePlay();
            }}
          >
            <span>
              Continue Playing
            </span>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Welcome;
