import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import { Stage, Layer, Line, Image, Text, Group } from "react-konva";
import { Stack, Button, Box, IconButton, Typography } from "@mui/material";
import { styled } from "@material-ui/styles";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import ColorLensIcon from '@mui/icons-material/ColorLens';
// import ColorLensIcon from "../../../assets/images/Palette_Yellow.svg"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteIcon from "@mui/icons-material/Delete";
import AppBlockingIcon from "@mui/icons-material/AppBlocking";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import CreateIcon from "@mui/icons-material/Create";
import { CirclePicker } from "react-color";
import { SocketContext } from "../../../contexts/socketConnection";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import { Base_URL } from "../Config/Config";
import useMousePosition from "../../../hooks/useMousePosition";
// import { Backdrop, Fade } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { Dropdown } from "react-bootstrap";
import useAnimatedState from "../../../hooks/useAnimatedState";

const IconStyle = styled("div")(() => ({
  backgroundColor: "#000",
  color: "#FFFB00",
  // borderRadius:"33px",
  border: "1px #1d1c1c solid",
  // outline:"1px #000 solid",
  // outlineOffset:'-3px',
  margin: 0,
  padding: "5px 8px",
}));

const ColorContainer = styled("div")(() => ({
  position: "absolute",
  zIndex: 9999,
}));

const ControlContainer = styled("div")(() => ({
  position: "absolute",
  backgroundColor: "#111",
  zIndex: 99,
}));

const colorOptions = [
  "#FFFFFF",
  "#ABB8C3",
  "#999999",
  "#666666",
  "#333333",
  "#000000",
  "#fa28ff",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#fffb00",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#FF0000",
  "#AA0000",
];
const debounce = (func, delay) => {
  let debounceTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}

export default function KonvaDrawing({ puzzleData, puzzleText }) {
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    setLines([])
    setMyLines([])
    socket.on("cleanDrawAll", (data) => {
      goClear(data, "all");
    });
    return () => {
      setLines([])
      setMyLines([])
      isMountedRef.current = false;
    };
  }, []);

  // console.log("puzzleText", puzzleText);

  const trashRef = useRef(null);
  const [tool, setTool] = useState("pen");
  const [lines, setLines] = useState([]);
  //!Draw our canvas Line and remove that and update global canvas setLines by socket
  // const [myLines, setMyLines] = useAnimatedState([]); //update state by requestAnimationFrame 60 frames/second
  const [myLines, setMyLines] = useState([]);
  const [show, setShow] = useState(false)
  const [selectColor, setSelectColor] = useState({
    show: false,
    color: localStorage.getItem("color") ?? "#FF0000",
  });
  const [flag, setFlag] = useState(false);
  // const [open, setOpen] = useState(false);
  const isDrawing = React.useRef(false);
  const [drawMode, setDrawMode] = React.useState(false);
  const socket = useContext(SocketContext);
  // const [fillColor,] = React.useState(false)
  const position = useMousePosition();
  const ref = useRef(null);
  const convaRef = useRef(null);
  const [showDel, setShowDel] = useState(false);
  const hintDetail = useSelector((state) => state.team.data.hintsTaken);
  const teamDetail = useSelector((state) => state.team.data);
  const currentUser = useSelector(state => state.team.data.currentUser ? state.team.data.currentUser : null)
  const getDelayTime = (data) => {
    if (data.length && currentUser !== null) {
      const ind = data.findIndex((elem) => (elem.name === currentUser));
      return 100 * ind;
    }
    return 0;
  }
  const eventDelayTime = useSelector(state => getDelayTime(state.team.data.teamDetails.teamMembers))
  // Open Color picker
  const handleOpenPicker = () => {
    setSelectColor({ ...selectColor, show: !selectColor.show });
  };
  // Change stroke color
  const changeColor = (color) => {
    localStorage.setItem('color', color.hex)
    setSelectColor({ ...selectColor, show: false, color: color.hex });
  };
  // Switch to draw mode
  const DrawToggle = () => {
    setDrawMode(!drawMode);
  };

  // Draw function
  function goDraw(data) {
    if (isDrawing.current === false) {
      setLines([...data]);
    }
  }


  //!Clear/Undo when another user cleanDraw/cleanDrawAll -> reDraw by setLines
  const goClear = (data, type) => {
    setLines([...data.data]);
    console.log("clearData", data);

    //!TESTING LINE
    // if (isDrawing.current === false) {
    if (type == "all") {
      setMyLines([])
    }

    if (isDrawing.current === false) {
      setMyLines([])
    }


    // setTimeout(() => setMyLines([...data.data]), 500);
  };

  // Undo Draw function
  const goUndo = (data) => {
    setLines([...data.data]);

    if (isDrawing.current === false) {
      setMyLines([])
    }

  };



  // Redo Draw function
  const goRedo = (data) => {
    setLines([...data.data]);

    if (isDrawing.current === false) {
      setMyLines([])
    }

  };

  const handleUndo = () => {
    // const t = setTimeout(() => {
    // setDrawMode(false)
    socket.emit("undoDraw", {
      roomName: teamDetail.teamDetails.teamName,
      teamId: teamDetail.teamDetails._id,
      user: teamDetail?.currentUser,
    });
    // }, 350);

    // return () => { clearTimeout(t) }
  };
  const handleDebounceUndo = debounce(handleUndo, 100);
  const handleRedo = () => {
    socket.emit("redoDraw", {
      roomName: teamDetail.teamDetails.teamName,
      teamId: teamDetail.teamDetails._id,
      user: teamDetail?.currentUser,
    });
  };
  const handleDebounceRedo = debounce(handleRedo, 100);

  // socket.emit("cleanDraw", {
  //   roomName: teamDetail.teamDetails.teamName,
  //   teamId: teamDetail.teamDetails._id,
  //   user: teamDetail?.currentUser
  // });

  const handleClearAll = () => {
    socket.emit("cleanDrawAll", {
      roomName: teamDetail.teamDetails.teamName,
      teamId: teamDetail.teamDetails._id,
    });
  };

  const handleClearMe = () => {
    console.log("currentuserrrrrrrr", teamDetail?.currentUser)

    socket.emit("cleanDraw", {
      roomName: teamDetail.teamDetails.teamName,
      teamId: teamDetail.teamDetails._id,
      user: teamDetail?.currentUser,
    });
  };



  // console.clear();



  const handleScroll = () => {
    setFlag(!flag);
    if (flag) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };





  // console.log("flag", flag);

  const container = document.querySelector("#imageContainer");
  // console.log({ container });


  //!onload redraw canvas or in re-render by setLine goDraw(data)
  useEffect(() => {
    socket.emit("getDrawing", {
      roomName: teamDetail.teamDetails.teamName,
      teamId: teamDetail.teamDetails._id,
    });
    socket.on("drawing", (data) => {
      if (isMountedRef) {

        goDraw(data);
      }
    });
    socket.on("getDrawing", (data) => {
      // convert from offset points
      goDraw(data.drawingData.map((daa) => daa));
      // goDraw(data.data);
    });
    socket.on("cleanDraw", (data) => {
      goClear(data, "mine");
    });
    socket.on("cleanDrawAll", (data) => {
      goClear(data, "all");
    });
    socket.on("undoDraw", (data) => {
      // isDrawing.current = false;
      // setDrawMode(false)
      goUndo(data);
    });
    socket.on("redoDraw", (data) => {
      goRedo(data);
    });
    socket.on('hintsTaken', (data) => {
      isDrawing.current = false;
      goDraw(data.drawingData.map((daa) => daa));
      // setShow(true)
    })

    socket.on("codeSuccess", () => {
      setLines([])
      setMyLines([]);
      setDrawMode(!drawMode);
      socket.emit("drawing", {
        drawData: [],
        roomName: teamDetail.teamDetails.teamName,
        teamId: teamDetail.teamDetails._id
      });
    })

    socket.on("continueSuccess", () => {
      setLines([])
      setMyLines([]);
      setDrawMode(!drawMode);
      socket.emit("drawing", {
        drawData: [],
        roomName: teamDetail.teamDetails.teamName,
        teamId: teamDetail.teamDetails._id
      });
    })
  }, []);
  // console.log("convaRef", convaRef.current);

  const handleClickOutside = (event) => {
    if (trashRef.current && !trashRef.current.contains(event.target)) {
      setShowDel(false);
    } else if (trashRef.current && trashRef.current.contains(event.target)) {
      setShowDel(true)
    }
  }



  /**
   * My Changes on another canvas so we can easily undo and update the global canvas
  */
  const myhandleMouseDown = useCallback((e) => {
    setShow(false)
    if (drawMode) {
      isDrawing.current = true;
      const pos = e.target.getStage().getPointerPosition();
      const Lid = uuidV4();
      setMyLines(prevLines => [...prevLines,
      {
        id: Lid,
        tool,
        user: teamDetail?.currentUser,
        color: selectColor.color,
        points: [pos.x, pos.y],
      },
      ]);

      // setLines(prevLines => [...prevLines,
      // {
      //   id: Lid,
      //   tool,
      //   user: teamDetail?.currentUser,
      //   color: selectColor.color,
      //   points: [pos.x, pos.y],
      // },
      // ]);
    }
  }, [drawMode, tool, teamDetail, selectColor]);


  //update our canvas lines and send on backend 
  const myhandleMouseUp = useCallback((e) => {
    if (drawMode && isMountedRef.current) {
      isDrawing.current = false;
      const sendLine = myLines[myLines.length - 1];
      if (sendLine) {
        const t = setTimeout(() => {
          if (isMountedRef.current) {


            socket.emit("drawing", {
              drawData: sendLine,
              roomName: teamDetail.teamDetails.teamName,
              teamId: teamDetail.teamDetails._id
            });


            //!TESTING LINE
            // setMyLines([])
            // setTimeout(() => setMyLines([]), 3000)
          }
        }, eventDelayTime);
        return () => {
          clearTimeout(t);
        };
      }
    }
  }, [drawMode, myLines, teamDetail, eventDelayTime]);

  const myhandleMouseLeave = useCallback((e) => {

    if (drawMode && isDrawing.current && isMountedRef.current) {
      isDrawing.current = false;
      const sendLine = myLines[myLines.length - 1];
      if (sendLine) {
        const t = setTimeout(() => {
          if (isMountedRef.current) {
            socket.emit("drawing", {
              drawData: sendLine,
              roomName: teamDetail.teamDetails.teamName,
              teamId: teamDetail.teamDetails._id
            });


            //!TESTING LINE
            // setMyLines([])
            // setTimeout(() => setMyLines([]), 3000)
          }
        }, eventDelayTime);
        return () => {
          clearTimeout(t);
        };
      }
    }
  }, [drawMode, myLines, teamDetail, eventDelayTime]);

  // const myhandleMouseLeave = (e) => {
  //   isDrawing.current = false;
  //   // setDrawMode(false)
  // }

  const myhandleMouseMove = useCallback((e) => {
    // no drawing - skipping
    if (!isDrawing.current || !drawMode) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    setMyLines(prevLines => {
      let lastLine = prevLines[prevLines.length - 1];
      if (lastLine)
        lastLine.points = lastLine.points.concat([point.x, point.y]);
      return [...prevLines.slice(0, prevLines.length - 1), lastLine];
    });
  }, [drawMode])


  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  })




  return (
    <>
      {/* {console.log("x", container && container.offsetWidth
        ? container?.offsetWidth
        : window.innerWidth - window.innerWidth * 0.3752)}
      {console.log("x1", ref.current.canvas.width)}
      {console.log("y", container && container.offsetHeight ? container?.offsetHeight : 420)}
      {console.log("y1", ref.current.canvas.height)} */}
      {/* <Modal
        show={open}
        onHide={() => setOpen(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Custom Modal Styling
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            style={{ width: "100%", height: "100%" }}
            src="https://cdn.pixabay.com/photo/2023/02/07/00/25/relaxed-7772958_960_720.jpg"
            alt="img"
          />
        </Modal.Body>
      </Modal> */}

      <div className="canvas-auto">
        <ControlContainer>
          <Stack flexDirection="row">
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                DrawToggle();
              }}
            >
              <IconStyle
                style={{
                  backgroundColor: drawMode ? "#FFFB00" : "#000",
                  color: drawMode ? "#1d1c1c" : "#FFFB00",
                }}
              >
                <CreateIcon fontSize="large" />
              </IconStyle>
            </IconButton>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                handleOpenPicker();
              }}
            >
              <IconStyle>
                <ColorLensIcon fontSize="large" style={{ transform: "rotate(-30deg)" }} />
              </IconStyle>
            </IconButton>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                handleDebounceUndo()
              }}
            >
              <IconStyle>
                <UndoIcon fontSize="large" />
              </IconStyle>
            </IconButton>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                handleDebounceRedo()
              }}
            >
              <IconStyle>
                <RedoIcon fontSize="large" />
              </IconStyle>
            </IconButton>
            <IconButton style={{ padding: 0 }} >
              <IconStyle>
                <DeleteIcon fontSize="large" onClick={() => setShowDel(!showDel)} />
              </IconStyle>
              <div style={{ display: "none" }} class={"delete-drop " + (showDel ? "show" : "")} >
                <ul>
                  <li onClick={handleClearMe}>
                    <p>
                      <DeleteIcon fontSize="large" /> Clear Mine
                    </p>
                  </li>
                  <li onClick={handleClearAll}>
                    <p>
                      <DeleteForeverIcon fontSize="large" /> Clear All
                    </p>
                  </li>
                </ul>
              </div>
            </IconButton>

            {/* <IconButton
            style={{ padding: 0 }}
            onClick={handleClearAll}
          >
            <IconStyle>
              <DeleteForeverIcon fontSize="large" />
            </IconStyle>
          </IconButton> */}
            {/* <IconButton
            style={{ padding: 0 }}
            onClick={() => {
              handleScroll();
            }}
          >
            <IconStyle>
              <AppBlockingIcon fontSize="large" />
            </IconStyle>
          </IconButton> */}
          </Stack>
          <ColorContainer
            style={{
              display: selectColor.show ? "block" : "none",
              backgroundColor: "black",
            }}
          >
            <CirclePicker
              colors={colorOptions}
              onChange={(color) => changeColor(color)}
            />
          </ColorContainer>
        </ControlContainer>
        {puzzleData !== "" &&
          puzzleData !== null &&
          puzzleData !== undefined ? (
          <div
            id="imageContainer"
            className="puzzle-image-container"
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // width: window.innerWidth - window.innerWidth * 0.3752,
              minWidth: "100px",
              minHeight: "100px",
              maxHeight: "100%",
              width: "1000px",
              maxWidth: "none",
              height: "auto",
            }}
          >
            <img className="bg-canvas-img" src={Base_URL + "/" + puzzleData} />
          </div>
        ) : null}

        <div id="global-canvas">
          <Stage
            ref={convaRef}
            // scaleX={(container && container.offsetWidth ? container?.offsetWidth : window.innerWidth - window.innerWidth * 0.3752) / ref.current.canvas.width}
            // scaleY={(container && container.offsetHeight ? container?.offsetHeight : 420) / ref.current.canvas.height}
            // width={
            //   container && container.offsetWidth
            //     ? container?.offsetWidth
            //     : window.innerWidth - window.innerWidth * 0.3752
            // }
            height={
              container && container.offsetHeight ? container?.offsetHeight : 420
            }
            width={1000}
            //height={360}
            style={{ zIndex: -1, height: "100%" }}
          >
            <Layer
              ref={ref}
              style={{ wordBreak: "break-all", whiteSpace: "normal" }}
            >
              {/* {/ <URLImage src={Base_URL+"/"+puzzleData} x={0} y={0} /> /} */}
              <Text
                text={puzzleText}
                width={500}
                y={250}
                fontSize={18}
                fontFamily="PFDinMonoMedium"
                padding={50}
                wrap="break-all"
                align="center"
                lineHeight={1.5}
                // whiteSpace="normal"
                // fontFamily="PFDinMonoBold"
                fill="#FFF"
              />

              {lines.length > 0 && lines.map((line, i) => (
                <Group key={i}>
                  <Line
                    perfectDrawEnabled={false}
                    points={line.points}
                    stroke={line.color}
                    strokeWidth={2}
                    tension={0.5}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation={
                      line?.tool === "eraser" ? "destination-out" : "source-over"
                    }
                  />
                </Group>
                // <LineComponent key={i} line={line} index={i} />
              ))}
            </Layer>
          </Stage>
        </div>


        <Stage

          // scaleX={(container && container.offsetWidth ? container?.offsetWidth : window.innerWidth - window.innerWidth * 0.3752) / ref.current.canvas.width}
          // scaleY={(container && container.offsetHeight ? container?.offsetHeight : 420) / ref.current.canvas.height}
          // width={
          //   container && container.offsetWidth
          //     ? container?.offsetWidth
          //     : window.innerWidth - window.innerWidth * 0.3752
          // }
          height={
            container && container.offsetHeight ? container?.offsetHeight : 420
          }
          width={1000}
          //height={360}
          onMouseDown={myhandleMouseDown}
          onMousemove={myhandleMouseMove}
          onMouseup={myhandleMouseUp}
          onMouseLeave={myhandleMouseLeave}
          onTouchStart={myhandleMouseDown}
          onTouchMove={myhandleMouseMove}
          onTouchEnd={myhandleMouseUp}
          style={{ background: "#1D1C1C", zIndex: -1, height: "100%" }}
        >
          <Layer
            ref={ref}
            style={{ wordBreak: "break-all", whiteSpace: "normal" }}
          >
            {/* {/ <URLImage src={Base_URL+"/"+puzzleData} x={0} y={0} /> /} */}
            <Text
              text={puzzleText}
              width={500}
              y={250}
              fontSize={18}
              fontFamily="PFDinMonoMedium"
              padding={50}
              wrap="break-all"
              align="center"
              lineHeight={1.5}
              // whiteSpace="normal"
              // fontFamily="PFDinMonoBold"
              fill="#FFF"
            />

            {myLines.length > 0 && myLines.map((line, i) => (
              <Group key={i}>
                <Line
                  points={line?.points}
                  stroke={line?.color}
                  strokeWidth={2}
                  tension={0.5}
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={
                    line?.tool === "eraser" ? "destination-out" : "source-over"
                  }
                />
              </Group>

              // <LineComponent key={i} line={line} index={i} />
            ))}
          </Layer>
        </Stage>
      </div>
    </>
  );
}



const LineComponent = React.memo(function LineComponent({ line, index }) {
  if (!line) {
    return null;
  }

  const {
    points,
    color,
    tool
  } = line;

  const lineProps = {
    points,
    stroke: color,
    strokeWidth: 2,
    tension: 0.5,
    lineCap: "round",
    lineJoin: "round",
    globalCompositeOperation: tool === "eraser" ? "destination-out" : "source-over",
  };

  return (
    <Group key={index}>
      <Line {...lineProps} />
    </Group>
  )
});



// function renderLines(myLines) {
//   return myLines.map((line, i) => <LineComponent key={i} line={line} index={i} />);
// }

// Usage:
// renderLines(myLines);



/**
 *
 */

// class URLImage extends React.Component {
//     state = {  image: null,};
//     componentDidMount() {
//       this.loadImage();
//     }
//     componentDidUpdate(oldProps) {
//       if (oldProps.src !== this.props.src) {
//         this.loadImage();
//       }
//     }
//     componentWillUnmount() {
//       this.image.removeEventListener('load', this.handleLoad);
//     }
//     loadImage() {
//       // save to "this" to remove "load" handler on unmount

//       this.image = new window.Image();
//       this.image.src = this.props.src;
//       this.image.addEventListener('load', this.handleLoad);
//     }
//     handleLoad = () => {
//       // after setState react-konva will update canvas and redraw the layer
//       // because "image" property is changed
//       this.setState({
//         image: this.image,
//       });
//       // if you keep same image object during source updates
//       // you will have to update layer manually:
//       console.log(this.imageNode)

//       this.imageNode.getLayer().batchDraw();
//     };
//     render() {

//       return (
//         <Image
//           x={this.props.x}
//           y={this.props.y}
//           width={window.innerWidth - (window.innerWidth)*0.3752}
//           height={420}
//           image={this.state.image}
//           ref={(node) => {
//             this.imageNode = node;
//           }}
//         />
//       );
//     }
// }